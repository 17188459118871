import React from "react";

const SystemNotificationsDeveloperDashboard = () => {
  return (
    <>
      <div>SystemNotificationsDeveloperDashboard</div>
    </>
  );
};

export default SystemNotificationsDeveloperDashboard;
