import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: { padding: 20, fontSize: 10 },
  title: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 10,
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  tableHeader: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    padding: 5,
    flex: 1,
    textAlign: "center",
  },
  tableCell: { padding: 5, flex: 1, textAlign: "center" },
});

const PdfGenerator = ({ reservations }) => {
  console.log(reservations, "reservations");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Reservation Details</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <Text style={styles.tableHeader}>Order ID</Text>
            <Text style={styles.tableHeader}>Load No</Text>
            <Text style={styles.tableHeader}>Reservation Date</Text>
            <Text style={styles.tableHeader}>Passenger</Text>
            <Text style={styles.tableHeader}>Pickup</Text>
            <Text style={styles.tableHeader}>Dropoff</Text>
            <Text style={styles.tableHeader}>Pick Up Time</Text>
            <Text style={styles.tableHeader}>Drop Off Time</Text>
            <Text style={styles.tableHeader}>Vehicle Type</Text>
            <Text style={styles.tableHeader}>Driver Amount</Text>
          </View>

          {/* Table Data */}
          {reservations.map((res, index) => {
            const dateString = res?.dateForReservation;
            const [datePart, timePart] = dateString.split("T");
            const [year, month, day] = datePart.split("-");
            const formattedDate = `${month}/${day}/${year}`;
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{res?.orderId}</Text>
                <Text style={styles.tableCell}>
                  {res?.specialNumber
                    ? res.specialNumber
                    : res?.loadNumber
                    ? res.loadNumber
                    : ""}
                </Text>
                <Text style={styles.tableCell}>{formattedDate}</Text>
                <Text style={styles.tableCell}>
                  {res?.customerId?.firstName + " " + res?.customerId?.lastName}
                </Text>
                <Text style={styles.tableCell}>
                  {res?.pickUpAddress?.address}
                </Text>
                <Text style={styles.tableCell}>
                  {res?.dropOffAddress?.address}
                </Text>
                <Text style={styles.tableCell}>
                  {(parseInt(res?.pickUpTime?.substring(0, 2)) === 0
                    ? 12
                    : parseInt(res?.pickUpTime?.substring(0, 2)) > 12
                    ? parseInt(res?.pickUpTime?.substring(0, 2)) - 12
                    : parseInt(res?.pickUpTime?.substring(0, 2))) +
                    ":" +
                    res?.pickUpTime?.substring(3, 5) +
                    (parseInt(res?.pickUpTime?.substring(0, 2)) > 11
                      ? "pm"
                      : "am")}
                </Text>
                <Text style={styles.tableCell}>
                  {(parseInt(res?.dropOffTime?.substring(0, 2)) === 0
                    ? 12
                    : parseInt(res?.dropOffTime?.substring(0, 2)) > 12
                    ? parseInt(res?.dropOffTime?.substring(0, 2)) - 12
                    : parseInt(res?.dropOffTime?.substring(0, 2))) +
                    ":" +
                    res?.dropOffTime?.substring(3, 5) +
                    (parseInt(res?.dropOffTime?.substring(0, 2)) > 11
                      ? "pm"
                      : "am")}
                </Text>
                <Text style={styles.tableCell}>{res?.type?.name}</Text>
                <Text style={styles.tableCell}>
                  {res?.fareAndDistance?.driverAmount
                    ? parseFloat(res?.fareAndDistance?.driverAmount)
                    : 0}
                </Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default PdfGenerator;
