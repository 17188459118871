import React, { useEffect, useState } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import LoginComponent from "../../components/loginComponents/LoginComponent";
import { tabSignIn } from "../../actions/tabActions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/landingWebsiteComponents/Footer";
import logo2 from "../../assets/images/login-logo2.png";
import { useNavigate } from "react-router-dom";
import {
  getUserByToken,
  userLogin,
  userLogout,
} from "../../actions/authActions";

const LoginPageV2 = () => {
  useEffect(() => {
    dispatch(tabSignIn());
  }, []);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const navigateUser = () => {
  //     navigate('/dashboard')
  // }

  const navigateUser = () => {
    navigate("/login");
  };

  const chkUser = async () => {
    if (auth.user.disable === true || auth.user.isDriver === true) {
      dispatch(
        userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
      );
    } else {
      dispatch(getUserByToken(auth?.tokens?.refresh?.token, navigate, auth));
    }
  };
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // if (localStorage.tokens === undefined){
    //     console.log("empty")
    // }
    // else {
    //     chkUser()
    // }
    if (auth?.tokens?.refresh?.token && auth?.user) {
      chkUser();
    }
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(windowSize)
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const userData = {
    email: values.email,
    password: values.password,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // dispatch(userLogin(userData, navigateUser))

    dispatch(userLogin(userData, navigate));
  };
  // const [isEmail, setIsEmail] = useState(true)
  // const changeEmailUsername = () => {
  //     setIsEmail((isEmail) => !isEmail)
  // }
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="newloginbg">
        <div className="login-section">
          <div className="container">
            <div
              className="row  align-items-center justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="login-card">
                  <div className="loginlogo mb-3">
                    <button className="bg-white">
                      <img className="loginImg" src={logo2} alt="" />
                    </button>
                  </div>
                  <div className="col-md-12">
                    <div className="loginlogo mx-4">
                      <h3>Sign in</h3>
                    </div>
                  </div>
                  <form
                    // action=""
                    onSubmit={handleSubmit}
                    autocomplete="off"
                    className="row p-4 pt-0"
                  >
                    <div className="col-md-12">
                      <label for="#" className="form-label">
                        Email address or Username
                      </label>
                      <div className="input-group mb-3">
                        <div
                          className="input-group-prepend"
                          // style={{ border: "1px solid #ced4da" }}
                        >
                          <span
                            className="input-group-text rounded-1"
                            id="basic-addon1"
                          >
                            <i className="fa fa-envelope m-1"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-changes form-controlnew rounded-1"
                          placeholder=""
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={values.email}
                          onChange={handleChange("email")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label for="#" className="form-label">
                        Password
                      </label>
                      <div className="form-group mb-3">
                        <div className="input-group" id="show_hide_password">
                          <div
                            className="input-group-prepend "
                            // style={{ border: "1px solid #ced4da" }}
                          >
                            <span
                              className="input-group-text rounded-1"
                              id="basic-addon2"
                            >
                              <i className="fa fa-lock m-1"></i>
                            </span>
                          </div>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-changes form-controlnew border-end-0"
                            // type="password"
                            value={values.password}
                            onChange={handleChange("password")}
                          />
                          <div className="input-group-text input-group-text-eye border-end border-start-0 ">
                            <button
                              type="button"
                              className="bg-white"
                              onClick={toggleShowPassword}
                            >
                              {/* <i className="fa fa-eye-slash" aria-hidden="true"></i> */}
                              <i
                                className={`fa ${
                                  showPassword ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {auth.error && (
                      <div className="col-md-12 mb-1">
                        <span className="text-danger f-12">
                          {" "}
                          *Invalid Credentials*
                        </span>
                      </div>
                    )}
                    {/* <div className="col-md-12 mb-3">
                      <div className="d-flex justify-content-between">
                        <div className="form-check">
                          <input
                            className=" rounded-1 form-check-input2"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Remember Me
                          </label>
                        </div>
                        <div>
                          <button className="forgetlink">Forget Password</button>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="d-grid mb-3">
                        <button
                          // href="#"
                          type="submit"
                          id="sendlogin"
                          className="btn btn-primary2 rounded-1"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginPageV2;
