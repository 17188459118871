import React, { useEffect, useState } from "react";
import { getReservationsByFacilityId } from "../../../actions/reservationAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ExcelExport from "../../../ExcelExport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfGenerator from "./PdfGenerator";
import shift1 from "../../../assets/images/load.png";

const AdminFacilityDetailsModals = ({
  openFacilityDropDown,
  showFacilityDropDown,
  current,
}) => {
  const reservation = useSelector((state) => state.reservation);
  const { facilityReservation } = reservation;
  // console.log(facilityReservation, "facilityReservationnnnnnnnnnnn");
  const [values, setValues] = useState({
    companyId: "",
    facilityId: "",
    status: "",
    from: "",
    to: "",
  });
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { ...values };
    for (let key of Object.keys(data)) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    dispatch(
      getReservationsByFacilityId({
        ...data,
      })
    );
  };
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  useEffect(() => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    const inputDate = moment(formattedChicagoDate);
    const firstDateOfWeek = inputDate.clone().startOf("isoWeek");
    const lastDateOfWeek = inputDate.clone().endOf("isoWeek");
    const formattedFirstDate = firstDateOfWeek.format("YYYY-MM-DD");
    const formattedLastDate = lastDateOfWeek.format("YYYY-MM-DD");
    setValues({
      ...values,
      ["from"]: formattedFirstDate,
      ["to"]: formattedLastDate,
      companyId: current.companyId,
      facilityId: current.facilityId,
    });
    dispatch(
      getReservationsByFacilityId({
        ...values,
        ["from"]: formattedFirstDate,
        ["to"]: formattedLastDate,
        companyId: current.companyId,
        facilityId: current.facilityId,
      })
    );
    // getData();
  }, [current]);
  // console.log(current, "current");

  const data = reservation?.facilityReservation?.map((item) => {
    const dateString = item?.dateForReservation;
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item?.orderId,
      "Load Number": item?.loadNumber,
      "Reservation Date": formattedDate,
      "Passenger First Name": item?.customerId?.firstName,
      "Passenger Last Name": item?.customerId?.lastName,
      "Pick Up Address": item?.pickUpAddress?.address,
      "Drop Off Address": item?.dropOffAddress?.address,
      // "Pick Up Time": item?.pickUpTime,
      "Pick Up Time":
        (parseInt(item?.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.pickUpTime?.substring(0, 2)) > 12
          ? parseInt(item?.pickUpTime?.substring(0, 2)) - 12
          : parseInt(item?.pickUpTime?.substring(0, 2))) +
        ":" +
        item?.pickUpTime?.substring(3, 5) +
        (parseInt(item?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      // "Drop Off Time": item?.dropOffTime,
      "Drop Off Time":
        (parseInt(item?.dropOffTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.dropOffTime?.substring(0, 2)) > 12
          ? parseInt(item?.dropOffTime?.substring(0, 2)) - 12
          : parseInt(item?.dropOffTime?.substring(0, 2))) +
        ":" +
        item?.dropOffTime?.substring(3, 5) +
        (parseInt(item?.dropOffTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      "Vehicle Type": item?.type?.name,
      // "Driver First Name": item?.driverId?.firstName,
      // "Driver Last Name": item?.driverId?.lastName,
      "Driver Amount": item?.fareAndDistance?.driverAmount
        ? parseFloat(item?.fareAndDistance?.driverAmount)
        : 0,
      Status: item?.status,
    };
  });

  // console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaa");

  return (
    <>
      <div
        className={`modal fade ${
          showFacilityDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openFacilityDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-content2">
            <form
            // onSubmit={handleSumbit}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Facility Name: {current?.name}
                </h5>
                {reservation?.facilityCount?.length > 0 && (
                  <>
                    <p className="modal-title" id="exampleModalLongTitle">
                      {" "}
                      Completed:{" "}
                      {reservation?.facilityCount[0]?.completed
                        ? reservation?.facilityCount[0]?.completed
                        : "0"}
                    </p>
                    <p className="modal-title" id="exampleModalLongTitle">
                      {" "}
                      Cancelled:{" "}
                      {reservation?.facilityCount[0]?.canceled
                        ? reservation?.facilityCount[0]?.canceled
                        : "0"}
                    </p>
                    <p className="modal-title" id="exampleModalLongTitle">
                      {" "}
                      No-Show:{" "}
                      {reservation?.facilityCount[0]?.noShow
                        ? reservation?.facilityCount[0]?.noShow
                        : "0"}
                    </p>
                  </>
                )}
                <div>
                  <div
                    className="btn-group mx-2"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      className="btn-sm"
                      data-toggle="tooltip"
                      data-original-title="Print"
                      onClick={() => window.print()}
                    >
                      <i className="fas fa-print"></i>
                    </button>
                    {facilityReservation?.length > 0 && (
                      <PDFDownloadLink
                        document={
                          <PdfGenerator
                            reservations={reservation?.facilityReservation}
                          />
                        }
                        fileName="Facility Report.pdf"
                      >
                        {({ loading }) => (
                          <button
                            type="button"
                            className="  btn-sm"
                            data-toggle="tooltip"
                            data-original-title="Download PDF File"
                          >
                            <i className="fas fa-file-pdf"></i>
                          </button>
                        )}
                      </PDFDownloadLink>
                    )}
                    {/* <button
                      type="button"
                      className="  btn-sm"
                      data-toggle="tooltip"
                      data-original-title="Export Excel File"
                    >
                      <i className="fas fa-file-excel"></i>
                    </button> */}
                    {facilityReservation?.length > 0 && (
                      <ExcelExport
                        excelData={data}
                        // fileName={`Route Distance Report ${moment().format(
                        //   "MM/DD/YYYY"
                        // )}`}
                        fileName={`Facility Report`}
                        custom
                      />
                    )}
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => openFacilityDropDown()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="row mb-3">
                  {/* <div className="col">
                    <label>Facility Name</label>
                    <input
                      type="text"
                      id="fromDate"
                      className="form-control height-35 f-14"
                    />
                  </div> */}
                  <div className="col">
                    <label>Status</label>
                    <div className="select-picker">
                      <select
                        name="default_task_status"
                        id="default_task_status"
                        className="form-control select-picker"
                        data-size="8"
                        tabindex="null"
                        style={{ height: "40px" }}
                        values={values.status}
                        onChange={handleChange("status")}
                      >
                        <option value="" disabled>
                          Please Select
                        </option>
                        <option value="All">All</option>
                        <option value="Canceled">Cancelled</option>
                        <option value="Completed">Completed</option>
                        <option value="No Show">No-Show</option>
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <label>From Date</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-control height-35 f-14"
                      value={values.from}
                      onChange={handleChange("from")}
                    />
                  </div>
                  <div className="col">
                    <label>To Date</label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-control height-35 f-14"
                      value={values.to}
                      onChange={handleChange("to")}
                    />
                  </div>
                  <div className="col d-flex align-items-end">
                    <button
                      id="filterBtn"
                      className="btn btn-primary w-100"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p>
                      Showing {reservation?.facilityReservation?.length} entries
                    </p>
                    <table
                      className="table border w-100 dataTable no-footer"
                      id="leads-table"
                      role="grid"
                      aria-describedby="leads-table_info"
                      style={{ overflowX: "auto" }}
                    >
                      <thead className="card-header">
                        <tr role="row">
                          <th
                            title="Order ID"
                            className="border-right align-table-header min-width-orderId"
                          >
                            Order ID{" "}
                          </th>
                          <th
                            title="Load#"
                            className=" border-right align-table-header"
                            tabindex="0"
                            aria-controls="leads-table"
                            rowspan="1"
                            colspan="1"
                            aria-label="Mobile: activate to sort column ascending"
                          >
                            Load#{" "}
                          </th>
                          <th
                            title="Time"
                            className=" border-right align-table-header"
                            tabindex="0"
                            aria-controls="leads-table"
                            rowspan="1"
                            colspan="1"
                            aria-label="Lead Agent: activate to sort column ascending"
                          >
                            Time{" "}
                          </th>
                          <th
                            title="Passenger"
                            className=" border-right align-table-header"
                          >
                            Passenger{" "}
                          </th>
                          <th
                            title="Pickup - Drop Off"
                            className=" border-right w-30 align-table-header"
                          >
                            Pickup - Drop Off
                          </th>
                          <th
                            title="Vehicle Type"
                            className=" border-right align-table-header"
                          >
                            Vehicle Type
                          </th>
                          <th
                            title="Driver Amount"
                            className=" border-right align-table-header"
                          >
                            Driver Amount
                          </th>
                          <th
                            title="Status"
                            className=" border-right align-table-header"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {facilityReservation?.length > 0 &&
                          facilityReservation.map((e) => (
                            <tr role="row" className="odd border">
                              <td className="border-right">
                                {e?.orderId}
                                &nbsp;
                                {e?.isOnlineBooking ? (
                                  <>
                                    <br />
                                    <i
                                      className="fa fa-globe"
                                      title="Quote Booking"
                                    ></i>{" "}
                                    &nbsp;
                                    {e?.actions?.some(
                                      (x) => x.action === "Send:Quotation"
                                    ) && (
                                      <i
                                        className="fa fa-share"
                                        title="Quotation Sent"
                                      ></i>
                                    )}
                                    &nbsp;
                                    {e?.actions?.some(
                                      (x) => x.action === "Url:Viewed"
                                    ) && (
                                      <i
                                        className="fa fa-eye"
                                        title="Customer viewed the link"
                                      ></i>
                                    )}
                                    &nbsp;
                                    {e?.isPaid && (
                                      <i
                                        className="fa fa-money"
                                        title="Paid"
                                      ></i>
                                    )}
                                    {e?.actions?.some(
                                      (x) => x.action === "Create:StandingOrder"
                                    ) && (
                                      <i
                                        className="fa fa-repeat"
                                        title="Standing Order"
                                      ></i>
                                    )}
                                  </>
                                ) : e?.isQuote ? (
                                  <>
                                    <br />
                                    <i
                                      className="fa fa-clipboard"
                                      title="Quotation"
                                    ></i>{" "}
                                    &nbsp;
                                    {e?.actions?.some(
                                      (x) => x.action === "Send:Quotation"
                                    ) && (
                                      <i
                                        className="fa fa-share"
                                        title="Quotation Sent"
                                      ></i>
                                    )}
                                    &nbsp;
                                    {e?.actions?.some(
                                      (x) => x.action === "Url:Viewed"
                                    ) && (
                                      <i
                                        className="fa fa-eye"
                                        title="Customer viewed the link"
                                      ></i>
                                    )}
                                    &nbsp;
                                    {e?.isPaid && (
                                      <i
                                        className="fa fa-money"
                                        title="Paid"
                                      ></i>
                                    )}
                                    {e?.actions?.some(
                                      (x) => x.action === "Create:StandingOrder"
                                    ) && (
                                      <i
                                        className="fa fa-repeat"
                                        title="Standing Order"
                                      ></i>
                                    )}
                                  </>
                                ) : (
                                  e?.actions?.some(
                                    (x) => x.action === "Create:StandingOrder"
                                  ) && (
                                    <>
                                      <br />
                                      <i
                                        className="fa fa-repeat"
                                        title="Standing Order"
                                      ></i>
                                    </>
                                  )
                                )}
                                <br />
                                {e?.connectedReservation &&
                                  e?.connectedReservation?.type +
                                    ":" +
                                    e?.connectedReservation?.reservationId
                                      ?.orderId}
                              </td>
                              <td className="border-right">
                                {e?.specialNumber
                                  ? e.specialNumber
                                  : e?.loadNumber
                                  ? e.loadNumber
                                  : ""}
                              </td>
                              <td className="border-right">
                                <strong>Pickup:</strong>{" "}
                                {parseInt(e.pickUpTime?.substring(0, 2)) === 0
                                  ? 12
                                  : parseInt(e.pickUpTime?.substring(0, 2)) > 12
                                  ? parseInt(e.pickUpTime?.substring(0, 2)) - 12
                                  : parseInt(e.pickUpTime?.substring(0, 2))}
                                {":" + e.pickUpTime?.substring(3, 5)}
                                {parseInt(e?.pickUpTime?.substring(0, 2)) > 11
                                  ? "pm"
                                  : "am"}
                                <br />
                                <strong>Dropoff:</strong>{" "}
                                {parseInt(e.dropOffTime?.substring(0, 2)) === 0
                                  ? 12
                                  : parseInt(e.dropOffTime?.substring(0, 2)) >
                                    12
                                  ? parseInt(e.dropOffTime?.substring(0, 2)) -
                                    12
                                  : parseInt(e.dropOffTime?.substring(0, 2))}
                                {":" + e.dropOffTime?.substring(3, 5)}
                                {parseInt(e?.dropOffTime?.substring(0, 2)) > 11
                                  ? "pm"
                                  : "am"}
                                <br />
                                {/* 1 hrs 5 mint */}
                                <strong>ETA:</strong>{" "}
                                {e?.fareAndDistance?.estimatedTime}
                              </td>
                              <td className="border-right">
                                <strong>
                                  {e?.customerId?.firstName}{" "}
                                  {e?.customerId?.lastName}
                                </strong>
                                {e?.customerId?.phone && (
                                  <>
                                    <br />
                                    {e?.customerId?.phone}
                                  </>
                                )}
                                {e?.facility && (
                                  <>
                                    <br />
                                    <span className="text-primary">
                                      {current?.name}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td className="border-right w-30">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div>
                                      <p className="f-12 font-medium mb-0">
                                        <strong>
                                          {e?.pickUpAddress?.address}
                                        </strong>{" "}
                                      </p>
                                    </div>
                                    <div>
                                      <time>
                                        {" "}
                                        {moment(
                                          e?.pickUpDate?.substring(0, 10)
                                        ).format("MM/DD/YYYY")}{" "}
                                        -{" "}
                                        {parseInt(
                                          e.pickUpTime?.substring(0, 2)
                                        ) === 0
                                          ? 12
                                          : parseInt(
                                              e.pickUpTime?.substring(0, 2)
                                            ) > 12
                                          ? parseInt(
                                              e.pickUpTime?.substring(0, 2)
                                            ) - 12
                                          : parseInt(
                                              e.pickUpTime?.substring(0, 2)
                                            )}
                                        {":" + e.pickUpTime?.substring(3, 5)}
                                        {parseInt(
                                          e?.pickUpTime?.substring(0, 2)
                                        ) > 11
                                          ? "pm"
                                          : "am"}
                                      </time>
                                    </div>
                                  </div>
                                  <div className="mt-4 px-4">
                                    <div className="hidden sm:block">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="50"
                                        height="8"
                                        viewBox="0 0 40 6"
                                        fill="none"
                                      >
                                        <path
                                          d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                          fill="black"
                                        ></path>
                                      </svg>
                                    </div>
                                    <p className="text-center font-medium">
                                      {/* <span>{e.distanceFromBaseLocation} {e.distanceFromPickToDropOff} {e.totalMileage} mi</span> */}
                                      {/* <span>{e?.distanceFromPickToDropOff?.toFixed(2)} mi</span> */}
                                      <span>
                                        {e?.fareAndDistance
                                          ?.apiDistanceFromPickToDropOff
                                          ? `${e?.fareAndDistance?.apiDistanceFromPickToDropOff.toFixed(
                                              2
                                            )} mi`
                                          : `${e?.fareAndDistance?.distanceFromPickToDropOff?.toFixed(
                                              2
                                            )} mi`}
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <div>
                                      <p className="f-12 font-medium mb-0">
                                        <strong>
                                          {e?.dropOffAddress?.address}
                                        </strong>
                                      </p>
                                      {/* <p className="f-12 mb-0">IL 60623</p> */}
                                    </div>
                                    <div>
                                      <time>
                                        {" "}
                                        {moment(
                                          e?.dropOffDate?.substring(0, 10)
                                        ).format("MM/DD/YYYY")}{" "}
                                        -{" "}
                                        {parseInt(
                                          e.dropOffTime?.substring(0, 2)
                                        ) === 0
                                          ? 12
                                          : parseInt(
                                              e.dropOffTime?.substring(0, 2)
                                            ) > 12
                                          ? parseInt(
                                              e.dropOffTime?.substring(0, 2)
                                            ) - 12
                                          : parseInt(
                                              e.dropOffTime?.substring(0, 2)
                                            )}
                                        {":" + e.dropOffTime?.substring(3, 5)}
                                        {parseInt(
                                          e.dropOffTime?.substring(0, 2)
                                        ) > 11
                                          ? "pm"
                                          : "am"}
                                      </time>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="border-right">
                                <strong>{e?.type?.name}</strong>
                                {e?.serviceName && (
                                  <i
                                    className="fa fa-exclamation-circle fa-w-16 text-info"
                                    title={e?.serviceName}
                                    data-toggle="modal"
                                    data-target="#myDriver"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                )}{" "}
                                <br />
                                {/* <span>{e?.serviceName}</span> */}
                              </td>
                              <td
                                className="border-right price-tag-detail"
                                // title="details"
                                // onClick={openDetailDropDown}
                              >
                                <strong
                                  className="price-tag-detail"
                                  title="details"
                                  // onClick={openDetailDropDown}
                                >
                                  {e.notBillable === true
                                    ? "Not Billable"
                                    : e?.fareAndDistance?.driverAmount
                                    ? "$" +
                                      parseFloat(
                                        e?.fareAndDistance?.driverAmount
                                      )?.toFixed(2)
                                    : "$0.00"}
                                  {/* $
                                          {e?.fareAndDistance?.driverAmount
                                            ? parseFloat(e?.fareAndDistance?.driverAmount)
                                            : 0} */}
                                </strong>
                                {/* {
                                          e?.serviceName && */}
                                {/* {auth?.user?.permissionId?.isAdmin && (
                                          <>
                                            <br />
                                            <strong>
                                              {sumRates(
                                                e?.type?._id ? e?.type?._id : e?.type?.id,
                                                e?.serviceName
                                              )}
                                            </strong>
                                          </>
                                        )} */}
                                {/* } */}
                                <i data-toggle="tooltip"></i>
                              </td>
                              <td className="border-right">{e?.status}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {facilityReservation?.length === 0 && (
                      <div
                        className="dash-stats-list"
                        style={{ textAlign: "center" }}
                      >
                        <br />
                        <br />
                        <img
                          alt=""
                          src={shift1}
                          style={{ width: "145px", opacity: "0.5" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Data Found</p>
                      </div>
                    )}
                    <div
                      id="leads-table_processing"
                      className="dataTables_processing card"
                      style={{ display: "none" }}
                    >
                      Processing...
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openFacilityDropDown()}
                  //   disabled={loading}
                >
                  Cancel
                </button>
                {/* <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  //   disabled={loading}
                >
                  Yes
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminFacilityDetailsModals;
