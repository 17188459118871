/** @format */

import {
  CHATS_GET_FAIL,
  CHATS_GET_REQUEST,
  CHATS_GET_SUCCESS,
  CHAT_SELECT_FAIL,
  CHAT_SELECT_REQUEST,
  CHAT_SELECT_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  MESSAGE_GET_FAIL,
  MESSAGE_GET_REQUEST,
  MESSAGE_GET_SUCCESS,
  MESSAGE_RECEIVE_SUCCESS,
  MESSAGE_SEND_FAIL,
  MESSAGE_SEND_REQUEST,
  MESSAGE_SEND_SUCCESS,
  CALL_SEND_REQUEST,
  CALL_SEND_SUCCESS,
  CALL_SEND_FAIL,
  POPUP_OFF,
  POPUP_ON,
  CALL_RECEIVED,
  CALL_DECLINED,
  MODIFY_SELECT_SUCCESS,
  READ_CHAT_SUCCESS,
  READALL_CHAT_FAIL,
  READALL_CHAT_REQUEST,
  READALL_CHAT_SUCCESS,
} from "../constants";

export const chatReducer = (
  state = {
    list: [],
    selectedChat: null,
    messages: { list: [] },
    callPopup: false,
    // currentCall: null,
  },
  action
) => {
  switch (action.type) {
    case CHATS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHATS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        // list: [action.payload],
        list: action.payload,
      };
    case CHATS_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CHAT_SELECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHAT_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedChat: action.payload,
      };
    case CHAT_SELECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // case MODIFY_SELECT_ACCEPTED: 1
    //   return {
    //     ...state,
    //     loading: false,
    //     selectedChat: {
    //       ...state.selectedChat,
    //       acceptedUser: action.payload,
    //     },
    //   };

    case MODIFY_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedChat: {
          ...state.selectedChat,
          noShowMessageAction: action.payload,
        },
      };

    case MESSAGE_GET_REQUEST:
      return {
        ...state,
        messages: {
          loading: true,
          list: [],
        },
      };
    case MESSAGE_GET_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
          list: action.payload,
        },
      };
    case MESSAGE_GET_FAIL:
      return {
        ...state,
        messages: {
          ...state.messages,
          list: [],
          error: action.payload,
        },
      };

    case MESSAGE_SEND_SUCCESS:
      // console.log(state?.list, "dddddddddd");
      return {
        ...state,
        loading: false,
        list: state?.list?.map((x) =>
          x.id === action.payload.chatId.id
            ? {
                ...x,
                latestMessage: action.payload,
                readBy: [{ readerId: action.payload.sender.id }],
              }
            : x
        ),
        messages: {
          ...state?.messages,
          list:
            state.selectedChat.id == action.payload.chatId.id
              ? [...state.messages.list, action.payload]
              : state.messages.list,
        },
      };
    case MESSAGE_RECEIVE_SUCCESS:
      const chatFound = state.list.find(
        (x) => x.id === action.payload.chatId.id
      );
      if (chatFound) {
        return {
          ...state,
          loading: false,
          // list: state.list.map((x) =>
          //   x.id === action.payload.chatId.id
          //     ? {
          //         ...x,
          //         latestMessage: action.payload,
          //         readBy: [{ readerId: action.payload.sender.id }],
          //       }
          //     : x
          // ),
          list: [
            ...state.list
              .filter((x) => x.id === action.payload.chatId.id)
              .map((x) => ({
                ...x,
                latestMessage: action.payload,
                unreadCount: action.payload.chatId.unreadCount,
                readBy: [{ readerId: action.payload.sender.id }],
              })),
            ...state.list.filter((x) => x.id !== action.payload.chatId.id),
          ],
          messages: {
            ...state.messages,
            list:
              state?.selectedChat?.id == action.payload.chatId.id &&
              (state.messages.list[state.messages.list.length - 1]?.dateTime !==
                action.payload.dateTime ||
                (state.messages.list[state.messages.list.length - 1]
                  ?.dateTime === action.payload.dateTime &&
                  state.messages.list[state.messages.list.length - 1]?.sender
                    .id !== action.payload.sender.id))
                ? [...state.messages.list, action.payload]
                : state.messages.list,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          // list: state.list.map((x) =>
          //   x.id === action.payload.chatId.id
          //     ? {
          //         ...x,
          //         latestMessage: action.payload,
          //         readBy: [{ readerId: action.payload.sender.id }],
          //       }
          //     : x
          // ),
          list: [
            {
              ...action.payload.chatId,
              latestMessage: action.payload,
              unreadCount: action.payload.chatId.unreadCount,
              readBy: [{ readerId: action.payload.sender.id }],
            },
            ...state.list,
          ],
          messages: {
            ...state.messages,
            list:
              state?.selectedChat?.id == action.payload.chatId.id &&
              (state.messages.list[state.messages.list.length - 1]?.dateTime !==
                action.payload.dateTime ||
                (state.messages.list[state.messages.list.length - 1]
                  ?.dateTime === action.payload.dateTime &&
                  state.messages.list[state.messages.list.length - 1]?.sender
                    .id !== action.payload.sender.id))
                ? [...state.messages.list, action.payload]
                : state.messages.list,
          },
        };
      }

    case GROUP_CREATE_SUCCESS:
      return { ...state, selectedChat: action.payload };

    case CALL_SEND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CALL_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.chatId.id
            ? {
                ...x,
                latestMessage: action.payload,
                readBy: [{ readerId: action.payload.sender.id }],
              }
            : x
        ),
        messages: {
          ...state.messages,
          list:
            state.selectedChat.id == action.payload.chatId.id
              ? [...state.messages.list, action.payload]
              : state.messages.list,
        },
        selectedChat: {
          ...state.selectedChat,
          latestMessage: action.payload,
        },
        // currentCall: action.payload,
        callPopup: true,
      };

    case CALL_SEND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        callPopup: false,
      };
    case POPUP_OFF:
      return {
        ...state,
        callPopup: false,
      };
    case POPUP_ON:
      return {
        ...state,
        callPopup: true,
      };

    case CALL_RECEIVED:
      return {
        ...state,
        selectedChat: {
          ...state?.selectedChat,
          latestMessage: {
            ...state?.selectedChat?.latestMessage,
            meta: action.payload.data.meta,
          },
        },
      };
    case CALL_DECLINED:
      return {
        ...state,
        selectedChat: {
          ...state?.selectedChat,
          latestMessage: {
            ...state?.selectedChat?.latestMessage,
            meta: action.payload.data.meta,
          },
        },
      };
    case READ_CHAT_SUCCESS:
      return {
        ...state,
        list: [
          ...state.list
            // .filter((x) => x.id === action.payload.id)
            .map((x) =>
              x.id === action.payload.id
                ? {
                    ...x,
                    unreadCount: action.payload.unreadCount,
                    readBy: action.payload.readBy,
                  }
                : x
            ),
        ],
      };
    case READALL_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case READALL_CHAT_SUCCESS:
      return {
        ...state,
        list: state.list.map((x) => ({
          ...x,
          unreadCount: x.unreadCount.map((e) =>
            e.userId === action.payload.userId ? { ...e, number: 0 } : e
          ),
          readBy: [
            ...x.readBy,
            { readerId: action.payload.userId, readAt: new Date() },
          ],
        })),
      };
    case READALL_CHAT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "RESET_STATE":
      return {
        ...state,
        selectedChat: null,
        messages: { list: [] },
      };
    default:
      return state;
  }
};
