/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import AdminClientnUserDelete from "../adminUserComponents/AdminClientnUserDelete";
// import AdminClientEditNewModal from "./AdminPassengerEditNewModal";
import AdminPassengerEditNewModal from "./AdminPassengerEditNewModal";
import AdminPassengerDelete from "./AdminPassengerDelete";
import { documentStatus } from "../../../actions/userActions";

const AdminNewPassengerViewTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  handleModal,
}) => {
  const vehicle = useSelector((state) => state.vehicle);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const navigate = useNavigate();
  const [showDropDown, setshowDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const dispatch = useDispatch();

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const auth = useSelector((state) => state.auth);
  const handleClick = (url) => {
    try {
      if (url) {
        const fileUrl = url;

        const fileExtension = fileUrl.split(".").pop().toLowerCase();

        const filename = `TermsDocument.${fileExtension}`;

        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", filename); // Set dynamic file name based on extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
      } else {
        throw new Error("No file URL found in response.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("There was an error downloading the document.");
    }
  };
  const handleAccept = () => {
    dispatch(
      documentStatus({
        _id: current?._id ? current?._id : current?.id,
        isDocumentVerified: "Accept",
      })
    );
  };
  const handleDecline = () => {
    dispatch(
      documentStatus({
        _id: current?._id ? current?._id : current?.id,
        isDocumentVerified: "Decline",
      })
    );
  };
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          {current?.firstName} {current?.lastName}
        </td>
        <td className="border-right">{current?.email}</td>
        <td className="border-right">{current?.phone}</td>
        {/* <td className="border-right">{current.address}</td> */}
        <td className="border-right">
          {!current?.verificationRequired ? (
            "Not Required"
          ) : current?.document ? (
            <button
              onClick={() => {
                handleClick(current?.document?.fileUrl);
              }}
              className="btn-primary"
            >
              {" "}
              Download
            </button>
          ) : (
            "No Document"
          )}
        </td>
        <td className="border-right">
          {!current?.verificationRequired
            ? "Already Access"
            : current?.isDocumentVerified === "Accept"
            ? "Approved"
            : current?.isDocumentVerified === "Decline"
            ? "Rejected"
            : current?.document
            ? "Pending"
            : "No Document"}
        </td>
        <td className=" text-right pr-20">
          {current?.verificationRequired && (
            <div className="task_view">
              <div className="dropdown">
                <Link
                  className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                  type="link"
                  data-toggle="dropdown"
                  onClick={openMoreVertDropDown}
                  aria-haspopup="true"
                  aria-expanded="false"
                  to=""
                >
                  <i className="icon-options-vertical icons"></i>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    showMoreVertDropDown
                      ? "dropdown-menu dropdown-menu-right show"
                      : "dropdown-menu dropdown-menu-right"
                  }`}
                  aria-labelledby="dropdownMenuLink-20"
                >
                  <button onClick={handleAccept} className="dropdown-item">
                    <i className="fa fa-check"> Accept</i>
                  </button>
                  <button onClick={handleAccept} className="dropdown-item">
                    <i className="fa fa-times"> Decline</i>
                  </button>
                </div>
              </div>
            </div>
          )}
        </td>
        {showDropDown && (
          <AdminPassengerEditNewModal
            current={current}
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        )}

        <AdminPassengerDelete
          current={current}
          showDeleteDropDown={showDeleteDropDown}
          openDeleteDropDown={openDeleteDropDown}
          index={i}
        />
      </tr>
    </>
  );
};
export default AdminNewPassengerViewTableRow;
