import React, { useEffect } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import { useNavigate } from "react-router-dom";
import NotAllowed from "../../components/NotAllowed";
import AdminFacilityDetailComponent from "../../components/adminComponents/adminPassengerViewComponents/AdminFacilityDetailComponent";
// import AdminClientFullDetailComponent from '../../components/adminComponents/adminClientViewComponents/AdminClientFullDetailComponent'

const AdminFacilityDetailPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth?.user?.permissionId?.passengers?.isAllow === false) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  Facility Details
                </h4>
              </div>
            </div>
            <AdminFacilityDetailComponent />
            {/* {
                auth?.user?.permissionId?.clients?.read === true ?
                <AdminClientFullDetailComponent />
                :
                <NotAllowed />
            } */}
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminFacilityDetailPage;
