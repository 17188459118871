import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNotificationDeveloper } from "../../actions/notificationDeveloper";
// import { useNavigate } from "react-router-dom";

const SysNotificationUpdateModal = ({
  showDropDown,
  openDropDown,
  current,
}) => {
  const auth = useSelector((state) => state.auth);
  const notificationDeveloper = useSelector(
    (state) => state.notificationDeveloper
  );
  const { loading } = notificationDeveloper;

  const [values, setValues] = useState({
    id: "",
    // companyId: Joi.string().custom(objectId).required(),
    subject: "",
    notificationsMessage: "",
    versionOfUpdates: "",
    date: "",
    startTime: "",
    endTime: "",
    isActive: "",
    timeZone: "",
  });

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currentMsg = async () => {
    try {
      setValues({
        id: "",
        subject: "",
        notificationsMessage: "",
        versionOfUpdates: "",
        date: "",
        startTime: "",
        endTime: "",
        isActive: "",
        timeZone: "",
      });
      setValues({
        // id: current.id,
        // companyId: current.companyId,
        id: current.id,
        subject: current.subject,
        notificationsMessage: current.notificationsMessage,
        versionOfUpdates: current.versionOfUpdates,
        date: current.date,
        isActive: current.isActive.toString(),
        startTime: current.startTime,
        endTime: current.endTime,
        timeZone: current.timeZone,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    currentMsg();
  }, [current]);
  const dispatch = useDispatch();
  const messageData = {
    _id: values.id,
    subject: values.subject,
    notificationsMessage: values.notificationsMessage,
    versionOfUpdates: values.versionOfUpdates,
    date: values.date,
    startTime: values.startTime,
    endTime: values.endTime,
    isActive: values.isActive,
    timeZone: values.timeZone,
  };
  const handleSuccess = () => {
    openDropDown();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // if (!messageData.standingOrder) {
    //   delete messageData.daysOfWeek;
    // }
    dispatch(updateNotificationDeveloper(messageData, handleSuccess));
  };

  return (
    <>
      <div
        className={`modal fade ${
          showDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ top: "8%" }}
        >
          <div className="modal-content">
            <form action="#" className="row p-20" onSubmit={handleSubmit}>
              <div className="col-lg-12">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {" "}
                    Edit
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => openDropDown()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <br />
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    id="driver"
                    min="0"
                    autocomplete="off"
                    value={values.subject}
                    onChange={handleChange("subject")}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-1">
                  <label className="f-14 text-dark mb-12" htmlFor="Name">
                    Notification:
                  </label>
                </div>
                <div className="dropdown bootstrap-select form-control select-picker">
                  <textarea
                    rows="4"
                    cols="50"
                    className="form-control f-14"
                    value={values?.notificationsMessage}
                    onChange={handleChange("notificationsMessage")}
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control height-35 f-14"
                    placeholder="."
                    id="driver"
                    min="0"
                    autocomplete="off"
                    value={values.date}
                    onChange={handleChange("date")}
                    // required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    Time Starts
                  </label>
                  <input
                    type="time"
                    className="form-control height-35 f-14"
                    placeholder="."
                    id="driver"
                    min="0"
                    autocomplete="off"
                    value={values.startTime}
                    onChange={handleChange("startTime")}
                    // required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    End Time
                  </label>
                  <input
                    type="time"
                    className="form-control height-35 f-14"
                    placeholder="."
                    id="driver"
                    min="0"
                    autocomplete="off"
                    value={values.endTime}
                    onChange={handleChange("endTime")}
                    // required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    Time zone (CST)
                  </label>
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder="."
                    id="driver"
                    min="0"
                    autocomplete="off"
                    disabled
                    value="GMT-6"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    Status
                  </label>
                  {current?.isActive === false ? (
                    <select
                      className="form-control select-picker"
                      style={{ height: "39px" }}
                      value={values.isActive.toString()}
                      onChange={handleChange("isActive")}
                    >
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>
                  ) : (
                    <select
                      className="form-control select-picker"
                      style={{ height: "39px" }}
                      value={values.isActive.toString()}
                      onChange={handleChange("isActive")}
                    >
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label
                    className="f-14 text-dark mb-12"
                    data-label=""
                    for="clnumber"
                  >
                    Version of the Updates
                  </label>
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder="."
                    id="driver"
                    min="0"
                    autocomplete="off"
                    value={values.versionOfUpdates}
                    onChange={handleChange("versionOfUpdates")}
                    // required
                  />
                </div>
              </div>

              <div className="col-md-12 pt-3 border-top">
                <button type="submit" className="btn btn-primary px-5 ">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SysNotificationUpdateModal;
