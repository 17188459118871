/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import SearchedByMap from "../components/adminComponents/Map/SearchedByMap";
import AdminSidebar from "../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminHeader from "../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import MapSidebar from "../components/adminComponents/adminHeaderSidebarTitleComponents/MapSidebar";
const containerStyle = {
  width: "auto",
  height: "100vh",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const options = {
  // zoomControlOptions: {
  //   position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
  // },
  // mapTypeControlOptions: {
  //   position: new window.google.maps.ControlPosition.TOP_RIGHT(),
  // },
  mapTypeControlOptions: {
    style: window?.google?.maps?.MapTypeControlStyle?.DROPDOWN_MENU,
    position: window?.google?.maps?.ControlPosition?.TOP_RIGHT,
  },
  // fullscreenControlOptions: {
  //   position: window.google.maps.ControlPosition.TOP_RIGHT,
  // },
};

const Demo7 = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
    libraries: ["places"],
  });
  const [zoom, setZoom] = useState(14);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  // const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const inputRef = useRef(null);
  const [dateTime, setDateTime] = useState(new Date().toLocaleString());
  useEffect(() => {
    const updateDateTime = () => {
      setDateTime(new Date().toLocaleString());
    };
    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (map && searchBox) {
      const listener = searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length === 0) return;
        const place = places[0];
        if (place.geometry && place.geometry.location) {
          map.setCenter(place.geometry.location);
          map.setZoom(zoom);
        }
      });
      return () => listener.remove();
    }
  }, [map, searchBox, zoom]);

  const handleZoomChange = (event) => {
    const newZoom = Number(event.target.value);
    setZoom(newZoom);
    if (map) {
      map.setZoom(newZoom);
    }
  };

  useEffect(() => {
    if (map) {
      map.setOptions({
        // zoomControl: false,
        mapTypeControl: false,
        scrollwheel: true,
        // streetViewControl: false,
        // fullscreenControl: false,
      });
      // Create a custom control with a FontAwesome icon and dropdown
      const controlDiv = document.createElement("div");
      controlDiv.style.margin = "10px";
      controlDiv.style.cursor = "pointer";
      controlDiv.style.position = "relative";

      // Create the button with the icon
      const controlButton = document.createElement("button");
      controlButton.innerHTML =
        '<i className="fas fa-layer-group" style="font-size: 30px;"></i>';
      controlButton.style.backgroundColor = "white";
      controlButton.style.border = "none";
      controlButton.style.padding = "5px";
      controlButton.style.borderRadius = "3px";
      controlButton.title = "Layers";

      // Create the dropdown menu
      const dropdownMenu = document.createElement("div");
      dropdownMenu.style.display = "none";
      dropdownMenu.style.position = "absolute";
      dropdownMenu.style.top = "40px";
      dropdownMenu.style.left = "-20px";
      dropdownMenu.style.backgroundColor = "white";
      dropdownMenu.style.boxShadow = "0px 4px 8px rgba(0,0,0,0.2)";
      dropdownMenu.style.borderRadius = "3px";
      dropdownMenu.style.zIndex = "1000";

      // Add options to the dropdown
      const mapOption = document.createElement("div");
      mapOption.innerHTML = "Map";
      mapOption.style.padding = "10px";
      mapOption.style.cursor = "pointer";
      dropdownMenu.appendChild(mapOption);

      const satelliteOption = document.createElement("div");
      satelliteOption.innerHTML = "Satellite";
      satelliteOption.style.padding = "10px";
      satelliteOption.style.cursor = "pointer";
      dropdownMenu.appendChild(satelliteOption);

      // Add event listeners to toggle the map type
      mapOption.addEventListener("click", () => {
        map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
        dropdownMenu.style.display = "none";
      });

      satelliteOption.addEventListener("click", () => {
        map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        dropdownMenu.style.display = "none";
      });

      // Toggle dropdown visibility on button click
      controlButton.addEventListener("click", () => {
        dropdownMenu.style.display =
          dropdownMenu.style.display === "none" ? "block" : "none";
      });

      // Close dropdown when mouse leaves the dropdown area
      dropdownMenu.addEventListener("mouseleave", () => {
        dropdownMenu.style.display = "none";
      });

      controlDiv.appendChild(controlButton);
      controlDiv.appendChild(dropdownMenu);

      // Add the custom control to the map
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        controlDiv
      );
    }
  }, [map]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // const toggleSidebar = () => {
  //   setIsSidebarVisible(!isSidebarVisible);
  // };

  const containerStyle = {
    width: isSidebarVisible ? "100%" : "100%",
    height: "100vh",
    transition: "width 0.3s ease",
  };
  const sidebarStyle = {
    width: "30%",
    height: "100vh",
    transition: "transform 0.3s ease",
    transform: isSidebarVisible ? "translateX(0)" : "translateX(-100%)",
  };
  const handleSidebarToggle = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  return (
    <>
      <AdminSidebar />
      <section className="main-container" id="fullscreen">
        <div style={{ display: "flex" }}>
          <div
            style={sidebarStyle}
            className={`${isSidebarVisible ? "show" : "hide"}`}
          >
            <MapSidebar />
          </div>
          <button
            style={{ padding: "10px", background: "#2570b8", color: "#fff" }}
            onClick={handleSidebarToggle}
            // onMouseEnter={() => setSidebarVisible(true)}
            // onMouseLeave={() => setSidebarVisible(false)}
          >
            {isSidebarVisible ? "<" : ">"}
          </button>
          <div style={containerStyle}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={(mapInstance) => setMap(mapInstance)}
              // options={options}
            >
              {/* Additional map features go here */}
            </GoogleMap>
            <div
              style={{
                position: "absolute",
                top: "0%", // Position the div at the top
                left: "50%", // Center horizontally
                transform: "translateX(-50%)", // Adjust to center horizontally
                width: "50%", // Full width of the map container
                height: "50px", // Height of the div
                backgroundColor: "white", // Background color
                borderBottomLeftRadius: "50px", // Rounded corners at the bottom-left
                borderBottomRightRadius: "50px", // Rounded corners at the bottom-right
                display: "flex", // Flexbox layout
                alignItems: "center", // Center vertically in the div
                justifyContent: "center", // Center horizontally in the div
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)", // Shadow for visual effect
                padding: "0 10px", // Padding to avoid text getting cut off
                boxSizing: "border-box", // Include padding in the width calculation
                textAlign: "center", // Center text horizontally
                // color: "lightskyblue",
              }}
            >
              <span>{dateTime}</span>
            </div>

            {/* <div className="controls d-flex flex-column align-items-center">
        <button
          className="btn btn-light mb-2"
          onClick={() => setZoom(zoom - 1)}
        >
          <i className="fas fa-search-minus"></i>
        </button>
        <input
          type="range"
          className="form-range mb-2"
          min="0"
          max="21"
          step="1"
          value={zoom}
          onChange={handleZoomChange}
        />
        <button
          className="btn btn-light mb-2"
          onClick={() => setZoom(zoom + 1)}
        >
          <i className="fas fa-search-plus"></i>
        </button>
        <StandaloneSearchBox
          onLoad={(searchBoxInstance) => setSearchBox(searchBoxInstance)}
          onPlacesChanged={() => {}}
        >
          <div className="search-bar d-flex align-items-center mt-2">
            <input
              type="text"
              className="form-control"
              ref={inputRef}
              placeholder="Search address"
            />
            <button
              className="btn btn-light ms-2"
              onClick={() => inputRef.current.focus()}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </StandaloneSearchBox>
      </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Demo7;
