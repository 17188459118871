/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editPermissionAction,
  getPermissionById,
} from "../../../actions/permissionActions";
import { useNavigate, useParams } from "react-router-dom";

const AdminPermissionEditModal = ({ showDropDown, openDropDown, current }) => {
  const auth = useSelector((state) => state.auth);
  const permission = useSelector((state) => state.permission);
  const { loading } = permission;

  const [values, setValues] = useState({
    // id: "",
    name: "",
    dashboard: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    booking: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    // clients: {
    //   isAllow: false,
    //   create: false,
    //   read: false,
    //   update: false,
    //   delete: false,
    // },
    passengers: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dispatching: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    track: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    reports: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    notes: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    routes: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    accounting: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    settings: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    permission: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    user: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dashboardDriver: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    // dashboardDispatcher: {
    //   isAllow: false,
    //   create: false,
    //   read: false,
    //   update: false,
    //   delete: false,
    // },
    myOrder: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    myRoutes: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    developer: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    _id: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const currentPermission = async () => {
    try {
      setValues({
        name: "",
        dashboard: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        booking: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        // clients: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        passengers: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        dispatching: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        track: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        reports: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        notes: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        routes: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        accounting: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        settings: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        permission: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        user: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        dashboardDriver: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        // dashboardDispatcher: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        myOrder: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        myRoutes: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        developer: {
          isAllow: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        _id: "",
      });
      setValues({
        companyId: permission?.permissionDetail?.permission?.companyId,
        name: permission?.permissionDetail?.permission?.name,
        dashboard: permission?.permissionDetail?.permission?.dashboard,
        booking: permission?.permissionDetail?.permission?.booking,
        // clients: permission?.permissionDetail?.permission?.clients,
        passengers: permission?.permissionDetail?.permission?.passengers,
        dispatching: permission?.permissionDetail?.permission?.dispatching,
        track: permission?.permissionDetail?.permission?.track,
        reports: permission?.permissionDetail?.permission?.reports,
        notes: permission?.permissionDetail?.permission?.notes,
        routes: permission?.permissionDetail?.permission?.routes,
        accounting: permission?.permissionDetail?.permission?.accounting,
        settings: permission?.permissionDetail?.permission?.settings,
        permission: permission?.permissionDetail?.permission?.permission,
        user: permission?.permissionDetail?.permission?.user,
        dashboardDriver:
          permission?.permissionDetail?.permission?.dashboardDriver,
        // dashboardDispatcher: permission?.permissionDetail?.permission?.dashboardDispatcher,
        myOrder: permission?.permissionDetail?.permission?.myOrder,
        myRoutes: permission?.permissionDetail?.permission?.myRoutes,
        developer: permission?.permissionDetail?.permission?.developer,
        // dashboard: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // booking: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // clients: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // dispatching: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // track: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // reports: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // settings: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // permission: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // user: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // dashboardDriver: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // dashboardDispatcher: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // myOrder: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // myRoutes: {
        //   isAllow: false,
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        _id: permission?.permissionDetail?.permission?.id,
      });
    } catch (error) {
      //   console.log(error)
    }
  };
  useEffect(() => {
    currentPermission();
  }, [permission?.permissionDetail?.permission]);

  const dispatch = useDispatch();

  const permissionData = {
    _id: values._id,
    name: values.name,
    companyId: values.companyId,
    dashboard: {
      isAllow: values?.dashboard?.isAllow,
      create: values?.dashboard?.create,
      read: values?.dashboard?.read,
      update: values?.dashboard?.update,
      delete: values?.dashboard?.delete,
    },
    booking: {
      isAllow: values?.booking?.isAllow,
      create: values?.booking?.create,
      read: values?.booking?.read,
      update: values?.booking?.update,
      delete: values?.booking?.delete,
    },
    // clients: {
    //   isAllow: values?.clients?.isAllow,
    //   create: values?.clients?.create,
    //   read: values?.clients?.read,
    //   update: values?.clients?.update,
    //   delete: values?.clients?.delete,
    // },
    passengers: {
      isAllow: values?.passengers?.isAllow,
      create: values?.passengers?.create,
      read: values?.passengers?.read,
      update: values?.passengers?.update,
      delete: values?.passengers?.delete,
    },
    dispatching: {
      isAllow: values?.dispatching?.isAllow,
      create: values?.dispatching?.create,
      read: values?.dispatching?.read,
      update: values?.dispatching?.update,
      delete: values?.dispatching?.delete,
    },
    track: {
      isAllow: values?.track?.isAllow,
      create: values?.track?.create,
      read: values?.track?.read,
      update: values?.track?.update,
      delete: values?.track?.delete,
    },
    reports: {
      isAllow: values?.reports?.isAllow,
      create: values?.reports?.create,
      read: values?.reports?.read,
      update: values?.reports?.update,
      delete: values?.reports?.delete,
    },
    notes: {
      isAllow: values?.notes?.isAllow,
      create: values?.notes?.create,
      read: values?.notes?.read,
      update: values?.notes?.update,
      delete: values?.notes?.delete,
    },
    routes: {
      isAllow: values?.routes?.isAllow,
      create: values?.routes?.create,
      read: values?.routes?.read,
      update: values?.routes?.update,
      delete: values?.routes?.delete,
    },
    accounting: {
      isAllow: values?.accounting?.isAllow,
      create: values?.accounting?.create,
      read: values?.accounting?.read,
      update: values?.accounting?.update,
      delete: values?.accounting?.delete,
    },
    settings: {
      isAllow: values?.settings?.isAllow,
      create: values?.settings?.create,
      read: values?.settings?.read,
      update: values?.settings?.update,
      delete: values?.settings?.delete,
    },
    permission: {
      isAllow: values?.permission?.isAllow,
      create: values?.permission?.create,
      read: values?.permission?.read,
      update: values?.permission?.update,
      delete: values?.permission?.delete,
    },
    user: {
      isAllow: values?.user?.isAllow,
      create: values?.user?.create,
      read: values?.user?.read,
      update: values?.user?.update,
      delete: values?.user?.delete,
    },
    dashboardDriver: {
      isAllow: values?.dashboardDriver?.isAllow,
      create: values?.dashboardDriver?.create,
      read: values?.dashboardDriver?.read,
      update: values?.dashboardDriver?.update,
      delete: values?.dashboardDriver?.delete,
    },
    // dashboardDispatcher: {
    //   isAllow: values?.dashboardDispatcher?.isAllow,
    //   create: values?.dashboardDispatcher?.create,
    //   read: values?.dashboardDispatcher?.read,
    //   update: values?.dashboardDispatcher?.update,
    //   delete: values?.dashboardDispatcher?.delete,
    // },
    myOrder: {
      isAllow: values?.myOrder?.isAllow,
      create: values?.myOrder?.create,
      read: values?.myOrder?.read,
      update: values?.myOrder?.update,
      delete: values?.myOrder?.delete,
    },
    myRoutes: {
      isAllow: values?.myRoutes?.isAllow,
      create: values?.myRoutes?.create,
      read: values?.myRoutes?.read,
      update: values?.myRoutes?.update,
      delete: values?.myRoutes?.delete,
    },
    developer: {
      isAllow: values?.developer?.isAllow,
      create: values?.developer?.create,
      read: values?.developer?.read,
      update: values?.developer?.update,
      delete: values?.developer?.delete,
    },
  };
  let navigate = useNavigate();
  const navigatePermission = () => {
    navigate("/permission");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(editPermissionAction(permissionData, navigatePermission));
  };
  const params = useParams();
  const allPermissionData = async (e) => {
    dispatch(getPermissionById({ _id: params.id }));
  };
  const shouldRenderSections =
    values?.dashboardDriver?.isAllow === true ||
    values?.myOrder?.isAllow === true ||
    values?.myRoutes?.isAllow === true;

  const adminPermissionSection =
    values?.dashboard?.isAllow === true ||
    values?.booking?.isAllow === true ||
    values?.passengers?.isAllow === true ||
    values?.dispatching?.isAllow === true ||
    values?.track?.isAllow === true ||
    values?.notes?.isAllow === true ||
    values?.routes?.isAllow === true ||
    values?.settings?.isAllow === true ||
    values?.permission?.isAllow === true ||
    values?.user?.isAllow === true ||
    values?.developer?.isAllow === true;

  const reportPermission =
    values?.dashboard?.isAllow === true ||
    values?.accounting?.isAllow === true ||
    values?.booking?.isAllow === true ||
    values?.dispatching?.isAllow === true ||
    // values?.routes?.isAllow === true ||
    values?.reports?.isAllow === true;

  useEffect(() => {
    allPermissionData();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white ">
          <div className="d-flex border">
            <div className="flex-grow-1">
              <div className="dataTables_length d-flex" id="leads-table_length">
                <div className="p-2">
                  <input
                    type="text"
                    id="permissionName"
                    className="form-control height-35 f-14"
                    required
                    placeholder="Role Title..."
                    autoComplete="false"
                    value={values.name}
                    onChange={handleChange("name")}
                    disabled={values.name === "Admin"}
                    style={{
                      cursor:
                        values.name === "Admin" ? "not-allowed" : "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-md-4 my-3 pl-5">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Module Name{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Create{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Read{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Update{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Delete{" "}
                    </h4>
                  </div>
                </div>
                {adminPermissionSection && (
                  <>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.dashboard?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      dashboard: {
                                        ...values.dashboard,
                                        isAllow: !values.dashboard.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle1"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle2"
                              >
                                Dashboard (Admin)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dashboard?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    create: !values.dashboard.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle2"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dashboard?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    read: !values.dashboard.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle3"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dashboard?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    update: !values.dashboard.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle4"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dashboard?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    delete: !values.dashboard.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle5"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.booking?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      booking: {
                                        ...values.booking,
                                        isAllow: !values.booking.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle6"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle7"
                              >
                                Booking
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.booking?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    create: !values.booking.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle7"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.booking?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    read: !values.booking.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle8"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.booking?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    update: !values.booking.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle9"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.booking?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    delete: !values.booking.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle10"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.dispatching?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      dispatching: {
                                        ...values.dispatching,
                                        isAllow: !values.dispatching.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle11"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle12"
                              >
                                Dispatching
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dispatching?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    create: !values.dispatching.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle12"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dispatching?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    read: !values.dispatching.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle13"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dispatching?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    update: !values.dispatching.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle14"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dispatching?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    delete: !values.dispatching.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle15"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.passengers?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      passengers: {
                                        ...values.passengers,
                                        isAllow: !values.passengers.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle16"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle17"
                              >
                                Passengers
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.passengers?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    create: !values.passengers.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle17"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.passengers?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    read: !values.passengers.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle18"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.passengers?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    update: !values.passengers.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle19"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.passengers?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    delete: !values.passengers.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle20"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.track?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      track: {
                                        ...values.track,
                                        isAllow: !values.track.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle21"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle22"
                              >
                                Track
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.track?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    create: !values.track.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle22"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.track?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    read: !values.track.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle23"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.track?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    update: !values.track.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle24"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.track?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    delete: !values.track.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle25"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.notes?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      notes: {
                                        ...values.notes,
                                        isAllow: !values.notes.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle31"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle32"
                              >
                                Notes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.notes?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  notes: {
                                    ...values.notes,
                                    create: !values.notes.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle32"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.notes?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  notes: {
                                    ...values.notes,
                                    read: !values.notes.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle33"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.notes?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  notes: {
                                    ...values.notes,
                                    update: !values.notes.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle34"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.notes?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  notes: {
                                    ...values.notes,
                                    delete: !values.notes.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle35"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.routes?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      routes: {
                                        ...values.routes,
                                        isAllow: !values.routes.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle36"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle37"
                              >
                                Routes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.routes?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    create: !values.routes.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle37"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.routes?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    read: !values.routes.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle38"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.routes?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    update: !values.routes.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle39"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.routes?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    delete: !values.routes.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle40"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.settings?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      settings: {
                                        ...values.settings,
                                        isAllow: !values.settings.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle46"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle47"
                              >
                                Settings
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.settings?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    create: !values.settings.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle47"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.settings?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    read: !values.settings.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle48"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.settings?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    update: !values.settings.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle49"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.settings?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    delete: !values.settings.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle50"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.permission?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      permission: {
                                        ...values.permission,
                                        isAllow: !values.permission.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle51"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle52"
                              >
                                Permission
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.permission?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    create: !values.permission.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle52"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.permission?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    read: !values.permission.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.permission?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    update: !values.permission.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle53"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.permission?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    delete: !values.permission.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle54"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.user?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      user: {
                                        ...values.user,
                                        isAllow: !values.user.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle55"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle56"
                              >
                                User
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.user?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    create: !values.user.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle56"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.user?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    read: !values.user.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle57"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.user?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    update: !values.user.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle58"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.user?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    delete: !values.user.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle59"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.developer?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      developer: {
                                        ...values.developer,
                                        isAllow: !values.developer.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="stoggle55"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle56"
                              >
                                Developer
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.developer?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  developer: {
                                    ...values.developer,
                                    create: !values.developer.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="stoggle56"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.developer?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  developer: {
                                    ...values.developer,
                                    read: !values.developer.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="stoggle57"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.developer?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  developer: {
                                    ...values.developer,
                                    update: !values.developer.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="stoggle58"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.developer?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  developer: {
                                    ...values.developer,
                                    delete: !values.developer.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="stoggle59"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {reportPermission && (
                  <>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  checked={values?.reports?.isAllow}
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      reports: {
                                        ...values.reports,
                                        isAllow: !values.reports.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle26"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle27"
                              >
                                Reports
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.reports?.create}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    create: !values.reports.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle27"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.reports?.read}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    read: !values.reports.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle28"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.reports?.update}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    update: !values.reports.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle29"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.reports?.delete}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    delete: !values.reports.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle30"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {values?.accounting?.isAllow === true && (
                  <div className="row">
                    <div className="col-md-4 pl-5">
                      <div className="form-group my-3">
                        <div className="d-flex">
                          <div className="btn-choice">
                            <label className="switch">
                              <input
                                checked={values?.accounting?.isAllow}
                                type="checkbox"
                                onChange={() =>
                                  setValues({
                                    ...values,
                                    accounting: {
                                      ...values.accounting,
                                      isAllow: !values.accounting.isAllow,
                                    },
                                  })
                                }
                                className="switch-input"
                                id="toggle41"
                              />
                              <span
                                className="switch-label"
                                data-on=""
                                data-off=""
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                          <div className="pl-3 my-2">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="toggle42"
                            >
                              Accounting
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 my-3">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            checked={values?.accounting?.create}
                            type="checkbox"
                            onChange={() =>
                              setValues({
                                ...values,
                                accounting: {
                                  ...values.accounting,
                                  create: !values.accounting.create,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle42"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 my-3">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            checked={values?.accounting?.read}
                            type="checkbox"
                            onChange={() =>
                              setValues({
                                ...values,
                                accounting: {
                                  ...values.accounting,
                                  read: !values.accounting.read,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle43"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 my-3">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            checked={values?.accounting?.update}
                            type="checkbox"
                            onChange={() =>
                              setValues({
                                ...values,
                                accounting: {
                                  ...values.accounting,
                                  update: !values.accounting.update,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle44"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 my-3">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            checked={values?.accounting?.delete}
                            type="checkbox"
                            onChange={() =>
                              setValues({
                                ...values,
                                accounting: {
                                  ...values.accounting,
                                  delete: !values.accounting.delete,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle45"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {shouldRenderSections && (
              <>
                <div className="row ">
                  <div className="col-md-4 pl-5">
                    <div className="form-group my-3">
                      <div className="d-flex">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.dashboardDriver?.isAllow}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboardDriver: {
                                    ...values.dashboardDriver,
                                    isAllow: !values.dashboardDriver.isAllow,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle60"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                        <div className="pl-3 my-2">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="toggle61"
                          >
                            Dashboard (Driver)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.dashboardDriver?.create}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              dashboardDriver: {
                                ...values.dashboardDriver,
                                create: !values.dashboardDriver.create,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle61"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.dashboardDriver?.read}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              dashboardDriver: {
                                ...values.dashboardDriver,
                                read: !values.dashboardDriver.read,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle62"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.dashboardDriver?.update}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              dashboardDriver: {
                                ...values.dashboardDriver,
                                update: !values.dashboardDriver.update,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle63"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.dashboardDriver?.delete}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              dashboardDriver: {
                                ...values.dashboardDriver,
                                delete: !values.dashboardDriver.delete,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle64"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-4 pl-5">
                    <div className="form-group my-3">
                      <div className="d-flex">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.myOrder?.isAllow}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myOrder: {
                                    ...values.myOrder,
                                    isAllow: !values.myOrder.isAllow,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle65"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                        <div className="pl-3 my-2">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="toggle66"
                          >
                            My Booking (Driver)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myOrder?.create}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myOrder: {
                                ...values.myOrder,
                                create: !values.myOrder.create,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle66"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myOrder?.read}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myOrder: {
                                ...values.myOrder,
                                read: !values.myOrder.read,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle67"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myOrder?.update}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myOrder: {
                                ...values.myOrder,
                                update: !values.myOrder.update,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle68"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myOrder?.delete}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myOrder: {
                                ...values.myOrder,
                                delete: !values.myOrder.delete,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle69"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-4 pl-5">
                    <div className="form-group my-3">
                      <div className="d-flex">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              checked={values?.myRoutes?.isAllow}
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myRoutes: {
                                    ...values.myRoutes,
                                    isAllow: !values.myRoutes.isAllow,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle70"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                        <div className="pl-3 my-2">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="toggle71"
                          >
                            My Routes (Driver)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myRoutes?.create}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myRoutes: {
                                ...values.myRoutes,
                                create: !values.myRoutes.create,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle71"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myRoutes?.read}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myRoutes: {
                                ...values.myRoutes,
                                read: !values.myRoutes.read,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle72"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myRoutes?.update}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myRoutes: {
                                ...values.myRoutes,
                                update: !values.myRoutes.update,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle73"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 my-3">
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          checked={values?.myRoutes?.delete}
                          type="checkbox"
                          onChange={() =>
                            setValues({
                              ...values,
                              myRoutes: {
                                ...values.myRoutes,
                                delete: !values.myRoutes.delete,
                              },
                            })
                          }
                          className="switch-input"
                          id="toggle74"
                        />
                        <span
                          className="switch-label"
                          data-on=""
                          data-off=""
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <div className="row ">
                        <div className="col-md-4 pl-5">
                            <div className="form-group my-3">
                                <div className="d-flex">
                                    <div className="btn-choice">
                                        <label className="switch">
                                            <input
                                                checked={values?.dashboardDispatcher?.isAllow}
                                                type="checkbox"
                                                onChange={() => setValues({
                                                    ...values,
                                                    dashboardDispatcher: {
                                                        ...values.dashboardDispatcher,
                                                        isAllow: !values.dashboardDispatcher.isAllow,
                                                    },
                                                })}
                                                className="switch-input"
                                                id="toggle" />
                                            <span
                                                className="switch-label"
                                                data-on=""
                                                data-off=""
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                    <div className="pl-3 my-2">
                                        <label
                                            className="f-14 text-dark mb-12"
                                            data-label=""
                                            htmlFor="clnumber"
                                        >
                                            Dashboard (Dispatcher)
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 my-3">
                            <div className="btn-choice">
                                <label className="switch">
                                    <input
                                        checked={values?.dashboardDispatcher?.create}
                                        type="checkbox"
                                        onChange={() => setValues({
                                            ...values,
                                            dashboardDispatcher: {
                                                ...values.dashboardDispatcher,
                                                create: !values.dashboardDispatcher.create,
                                            },
                                        })}
                                        className="switch-input"
                                        id="toggle" />
                                    <span
                                        className="switch-label"
                                        data-on=""
                                        data-off=""
                                    ></span>
                                    <span className="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-2 my-3">
                            <div className="btn-choice">
                                <label className="switch">
                                    <input
                                        checked={values?.dashboardDispatcher?.read}
                                        type="checkbox"
                                        onChange={() => setValues({
                                            ...values,
                                            dashboardDispatcher: {
                                                ...values.dashboardDispatcher,
                                                read: !values.dashboardDispatcher.read,
                                            },
                                        })}
                                        className="switch-input"
                                        id="toggle" />
                                    <span
                                        className="switch-label"
                                        data-on=""
                                        data-off=""
                                    ></span>
                                    <span className="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-2 my-3">
                            <div className="btn-choice">
                                <label className="switch">
                                    <input
                                        checked={values?.dashboardDispatcher?.update}
                                        type="checkbox"
                                        onChange={() => setValues({
                                            ...values,
                                            dashboardDispatcher: {
                                                ...values.dashboardDispatcher,
                                                update: !values.dashboardDispatcher.update,
                                            },
                                        })}
                                        className="switch-input"
                                        id="toggle" />
                                    <span
                                        className="switch-label"
                                        data-on=""
                                        data-off=""
                                    ></span>
                                    <span className="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-2 my-3">
                            <div className="btn-choice">
                                <label className="switch">
                                    <input
                                        checked={values?.dashboardDispatcher?.delete}
                                        type="checkbox"
                                        onChange={() => setValues({
                                            ...values,
                                            dashboardDispatcher: {
                                                ...values.dashboardDispatcher,
                                                delete: !values.dashboardDispatcher.delete,
                                            },
                                        })}
                                        className="switch-input"
                                        id="toggle" />
                                    <span
                                        className="switch-label"
                                        data-on=""
                                        data-off=""
                                    ></span>
                                    <span className="switch-handle"></span>
                                </label>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="row">
                                <div className="col-md-4 pl-5">
                                    <div className="form-group my-3">
                                        <div className="d-flex">
                                            <div className="btn-choice">
                                                <label className="switch">
                                                    <input
                                                        checked={values?.clients?.isAllow}
                                                        type="checkbox"
                                                        onChange={() => setValues({
                                                            ...values,
                                                            clients: {
                                                                ...values.clients,
                                                                isAllow: !values.clients.isAllow,
                                                            },
                                                        })}
                                                        className="switch-input"
                                                        id="toggle" />
                                                    <span
                                                        className="switch-label"
                                                        data-on=""
                                                        data-off=""
                                                    ></span>
                                                    <span className="switch-handle"></span>
                                                </label>
                                            </div>
                                            <div className="pl-3 my-2">
                                                <label
                                                    className="f-14 text-dark mb-12"
                                                    data-label=""
                                                    htmlFor="clnumber"
                                                >
                                                    Clients
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 my-3">
                                    <div className="btn-choice">
                                        <label className="switch">
                                            <input
                                                checked={values?.clients?.create}
                                                type="checkbox"
                                                onChange={() => setValues({
                                                    ...values,
                                                    clients: {
                                                        ...values.clients,
                                                        create: !values.clients.create,
                                                    },
                                                })}
                                                className="switch-input"
                                                id="toggle" />
                                            <span
                                                className="switch-label"
                                                data-on=""
                                                data-off=""
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 my-3">
                                    <div className="btn-choice">
                                        <label className="switch">
                                            <input
                                                checked={values?.clients?.read}
                                                type="checkbox"
                                                onChange={() => setValues({
                                                    ...values,
                                                    clients: {
                                                        ...values.clients,
                                                        read: !values.clients.read,
                                                    },
                                                })}
                                                className="switch-input"
                                                id="toggle" />
                                            <span
                                                className="switch-label"
                                                data-on=""
                                                data-off=""
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 my-3">
                                    <div className="btn-choice">
                                        <label className="switch">
                                            <input
                                                checked={values?.clients?.update}
                                                type="checkbox"
                                                onChange={() => setValues({
                                                    ...values,
                                                    clients: {
                                                        ...values.clients,
                                                        update: !values.clients.update,
                                                    },
                                                })}
                                                className="switch-input"
                                                id="toggle" />
                                            <span
                                                className="switch-label"
                                                data-on=""
                                                data-off=""
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-2 my-3">
                                    <div className="btn-choice">
                                        <label className="switch">
                                            <input
                                                checked={values?.clients?.delete}
                                                type="checkbox"
                                                onChange={() => setValues({
                                                    ...values,
                                                    clients: {
                                                        ...values.clients,
                                                        delete: !values.clients.delete,
                                                    },
                                                })}
                                                className="switch-input"
                                                id="toggle" />
                                            <span
                                                className="switch-label"
                                                data-on=""
                                                data-off=""
                                            ></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
            <div className="d-flex border">
              <div className="flex-grow-1">
                <div
                  className="dataTables_info"
                  id="leads-table_info"
                  role="status"
                  aria-live="polite"
                ></div>
              </div>
              <div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="leads-table_paginate"
                >
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    // onClick={handleSubmit}
                    disabled={loading}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default AdminPermissionEditModal;
