import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminFacilitiesViewTable from "../../components/adminComponents/adminPassengerViewComponents/AdminFacilitiesViewTable";
import AdminSettingAddFacilityModal from "../../components/adminComponents/adminSettingComponents/AdminSettingAddFacilityModal";
// import NotAllowed from "../../components/NotAllowed";
// import AdminPassengerViewTable from "../../components/adminComponents/adminPassengerViewComponents/AdminPassengerViewTable";
// import AdminClientAddComponent from "../../components/adminComponents/adminClientViewComponents/AdminClientAddComponent";
// import AdminClientViewTable from "../../components/adminComponents/adminClientViewComponents/AdminClientViewTable";

const AdminFacilitiesViewPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth?.user?.permissionId?.passengers?.isAllow === false) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Passengers Facilities{" "}
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <button
                  //   to="/create-passenger"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                  id="add-lead"
                  onClick={openDropDown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>{" "}
                  Add Facility
                </button>
              </div>
            </div>
            <AdminFacilitiesViewTable />
            <AdminSettingAddFacilityModal
              showDropDown={showDropDown}
              openDropDown={openDropDown}
            />
            {/* {
                auth?.user?.permissionId?.clients?.read === true ?
                <AdminClientViewTable />
                :
                <NotAllowed />
            } */}
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminFacilitiesViewPage;
