/** @format */

// import moment from "moment";
import React, { useEffect, useState } from "react";
// import LoadingComponentRideModal from "../../LoadingComponentRideModal";
// import { SERVERADDRESS } from "../../../constants";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationById,
  sendQuotation,
  // undoInprogressAction,
  // undoStartAction,
  updateReservation2,
} from "../../../actions/reservationAction";
import moment from "moment";

const AdminSendQuoteModal = ({ sendQuote, openSendQuote, current, i }) => {
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;

  const { reservationDetails } = reservation;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isResend, setIsResend] = useState(false);
  const [values, setValues] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    passengerId: "",
    name: "",
    amount: "",
    email: "",
    pickUpDate: "",
    pickUpTime: "",
    pickUpAddress: "",
    dropOffAddress: "",
    pickUpCoordinates: "",
    dropOffCoordinates: "",
  });
  // const dataLoaded = async () => {
  //   const id = current._id ? current._id : current.id;
  //   dispatch(
  //     getReservationById({
  //       _id: id,
  //     })
  //   );
  // };
  useEffect(() => {
    setValues({
      _id: current?._id ? current?._id : current?.id,
      name:
        current?.customerId?.firstName + " " + current?.customerId?.lastName,
      firstName: current?.customerId?.firstName,
      lastName: current?.customerId?.lastName,
      passengerId: current?.customerId?._id
        ? current?.customerId?._id
        : current?.customerId?.id,
      amount: current?.totalAmount,
      email: current?.customerId?.email,
      pickUpDate: moment(current?.pickUpDate?.substring(0, 10)).format(
        "MM/DD/YYYY"
      ),
      pickUpTime:
        parseInt(current.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : (parseInt(current.pickUpTime?.substring(0, 2)) > 12
              ? parseInt(current.pickUpTime?.substring(0, 2)) - 12
              : parseInt(current.pickUpTime?.substring(0, 2))) +
            ":" +
            current.pickUpTime?.substring(3, 5) +
            (parseInt(current?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      pickUpAddress: current?.pickUpAddress?.address,
      dropOffAddress: current?.dropOffAddress?.address,
      dispatcherId: auth.user.id,
      serviceType: current?.type?.name,
      pickUpCoordinates: {
        latitude: current?.pickUpAddress?.latitude,
        longitude: current?.pickUpAddress?.longitude,
      },
      dropOffCoordinates: {
        latitude: current?.dropOffAddress?.latitude,
        longitude: current?.dropOffAddress?.longitude,
      },
    });
    if (current?.connectedReservation) {
      findReturn();
    }
    if (current?.actions?.some((x) => x.action === "Send:Quotation")) {
      setIsResend(true);
    }
    // dataLoaded();
  }, [sendQuote, current]);
  // useEffect(() => {
  //   if (reservationDetails) {
  //     setValues(reservationDetails);
  //   }
  // }, [reservationDetails]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const sendQuotationViaEmail = async (event) => {
    event.preventDefault();
    const id = values._id ? values._id : values.id;
    delete values.pickUpCoordinates;
    delete values.dropOffCoordinates;
    dispatch(sendQuotation(values, openSendQuote));
  };
  const openGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, "_blank"); // Opens in a new tab
  };
  const [foundReturn, setFoundReturn] = useState("");
  const findReturn = () => {
    let returnReservation = "";
    returnReservation = reservation?.reservation?.quote?.find((x) => {
      const checkingId = x?._id ? x?._id : x?.id;
      const checkedId = current?.connectedReservation?.reservationId?._id
        ? current?.connectedReservation?.reservationId?._id
        : current?.connectedReservation?.reservationId?.id;
      return checkingId == checkedId;
    });
    if (!returnReservation) {
      returnReservation = reservation?.reservation?.online?.find((x) => {
        const checkingId = x?._id ? x?._id : x?.id;
        const checkedId = current?.connectedReservation?.reservationId?._id
          ? current?.connectedReservation?.reservationId?._id
          : current?.connectedReservation?.reservationId?.id;
        return checkingId == checkedId;
      });
    }

    setFoundReturn(returnReservation);
  };
  const calculateFare = () => {
    let sumOfAmounts = current?.totalAmount + foundReturn?.totalAmount;
    return sumOfAmounts;
  };
  return (
    <>
      <div
        className={`modal centralized-modal fade ${
          sendQuote ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openSendQuote(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content small-modal">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {isResend ? "Resend Quote" : "Send Quote"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openSendQuote()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={sendQuotationViaEmail}>
              <div className="modal-body">
                {
                  <>
                    {/* <div className="row"> */}
                    <div className="row g-3 border-bottom pb-2">
                      <div
                        className="col-md-6"
                        // style={{ borderBottom: "1px solid #dee2e6" }}
                      >
                        {/* <div className="form-group my-3">
                          <label
                            className=""
                            data-label=""
                            htmlFor="after_days"
                          >
                            <strong>Name:</strong>
                          </label>
                          <div
                            type="text"
                            placeholder="Enter Your Name"
                            autoComplete="false"
                            // onChange={handleChange("firstName")}
                            // required
                            disabled
                            maxLength={35}
                          />
                          {values.name}
                        </div> */}
                        <p className="f-16 f-w-600">Name:</p>
                        <p className="f-16">{values.name}</p>
                      </div>
                      <div
                        className="col-md-6"
                        // style={{ borderBottom: "1px solid #dee2e6" }}
                      >
                        {/* <div className="form-group my-3">
                          <label data-label="" htmlFor="after_days">
                            <strong>Amount:</strong>
                          </label>
                          <div
                            type="text"
                            placeholder="Enter Amount"
                            autoComplete="false"
                            // onChange={handleChange("firstName")}
                            // required
                            disabled
                            maxLength={35}
                          />
                          ${values.amount}
                        </div> */}
                        <p className="f-16 f-w-600">Amount:</p>
                        <p className="f-16">
                          $
                          {!current?.connectedReservation
                            ? values.amount
                            : calculateFare()}
                        </p>
                      </div>
                    </div>
                    <div className="row g-3 mt-3 border-bottom pb-2">
                      <div
                        className="col-md-6"
                        // style={{ borderBottom: "1px solid #dee2e6" }}
                      >
                        {/* <div className="form-group my-3">
                        <label data-label="" htmlFor="after_days">
                          <strong>Email:</strong>
                        </label>
                        <div
                          type="text"
                          placeholder="Enter Your Email"
                          autoComplete="false"
                          // onChange={handleChange("email")}
                          // required
                          disabled={current?.customerId?.email}
                          maxLength={35}
                        />
                        {values.email}
                      </div> */}
                        <p className="f-16 f-w-600">Email:</p>
                        <p className="f-16">{values.email}</p>
                      </div>

                      <div
                        className="col-md-6"
                        // style={{ borderBottom: "1px solid #dee2e6" }}
                      >
                        {/* <div className="form-group my-3">
                        <label data-label="" htmlFor="after_days">
                          <strong>Pickup Date and Time:</strong>
                        </label>
                        <div
                          type="text"
                          // className="form-control height-35 f-14"
                          placeholder="Enter Pickup Date and Time"
                          autoComplete="false"
                          // onChange={handleChange("firstName")}
                          required
                          disabled
                          // maxLength={35}
                        />
                        {values.pickUpDate + " @ " + values.pickUpTime}
                      </div> */}
                        <p className="f-16 f-w-600">Pickup Date and Time:</p>
                        <p className="f-16">
                          {values.pickUpDate + " @ " + values.pickUpTime}
                        </p>
                      </div>
                    </div>
                    <div className="row g-3 mt-3 border-bottom pb-2">
                      <div
                        className="col-md-6"
                        // style={{ borderBottom: "1px solid #dee2e6" }}
                      >
                        {/* <div className="form-group my-3">
                        <label data-label="" htmlFor="after_days">
                          <strong>Pickup Address:</strong>
                        </label>
                        <div
                          type="text"
                          placeholder="Enter Pickup Address"
                          autoComplete="false"
                          // onChange={handleChange("firstName")}
                          required
                          disabled
                          // maxLength={35}
                        />
                        {values.pickUpAddress}
                      </div> */}
                        <p className="f-16 f-w-600">Pickup Address:</p>
                        <p
                          className="f-16 cursor-pointer"
                          onClick={() =>
                            openGoogleMaps(
                              values?.pickUpCoordinates?.latitude,
                              values?.pickUpCoordinates?.longitude
                            )
                          }
                        >
                          {values.pickUpAddress}
                        </p>
                      </div>
                      <div
                        className="col-md-6"
                        // style={{ borderBottom: "1px solid #dee2e6" }}
                      >
                        {/* <div className="form-group my-3">
                        <label className="" data-label="" htmlFor="after_days">
                          <strong>Drop-off Address:</strong>
                        </label>
                        <div
                          type="text"
                          placeholder="Enter Pickup Address"
                          autoComplete="false"
                          // onChange={handleChange("firstName")}
                          required
                          disabled
                          // maxLength={35}
                        />
                        {values.dropOffAddress}
                      </div> */}
                        <p className="f-16 f-w-600">Drop-off Address:</p>
                        <p
                          className="f-16 cursor-pointer"
                          onClick={() =>
                            openGoogleMaps(
                              values?.dropOffCoordinates?.latitude,
                              values?.dropOffCoordinates?.longitude
                            )
                          }
                        >
                          {values.dropOffAddress}
                        </p>
                      </div>
                    </div>
                    {/* </div> */}
                  </>
                }
              </div>
              <div className="modal-footer bg-additional-grey">
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  disabled={loading}
                  // onClick={() => handleUpdate()}
                >
                  <i className="fa fa-paper-plane-o"></i>{" "}
                  {isResend ? "Resend" : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSendQuoteModal;
