/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AdminSettingPaymentAddCompanyModal from "../adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import AdminFacilityDelete from "./AdminFacilityDelete";
import AdminFacilityDetailsModals from "./AdminFacilityDetailsModals";
// import AdminPassengerEditNewModal from "./AdminPassengerEditNewModal";
// import AdminPassengerDelete from "./AdminPassengerDelete";
// import { createIndividualClient } from "../../../actions/userActions";
// import AdminGrantPermissionModal from "./AdminGrantPermissionModal";

const AdminFacilitiesViewTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  handleModal,
}) => {
  const kpi = useSelector((state) => state.kpi);
  const students = useSelector((state) => state.students);
  const { facility } = kpi;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const navigate = useNavigate();
  const [showDropDown, setshowDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const [showFacilityDropDown, setshowFacilityDropDown] = useState(false);
  const dispatch = useDispatch();

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const openFacilityDropDown = () => {
    setshowFacilityDropDown((showFacilityDropDown) => !showFacilityDropDown);
  };
  const auth = useSelector((state) => state.auth);

  const data = {
    companyId: current.companyId,
    firstName: current.firstName,
    lastName: current.lastName,
    email: current.email,
    isClient: true,
  };
  const totalCount =
    students?.list?.filter(
      (student) => student.facility === current?.facilityId
    ).length +
    students?.routePassengerList?.filter(
      (student) => student.facility === current?.facilityId
    ).length;
  const findFacility = (id) => {
    const exist = facility.find((f) => f.externalCompanyId == id);
    if (exist) {
      return exist.description;
    } else {
      return "";
    }
  };
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.name}</td>
        <td className="border-right">{current?.email}</td>
        <td className="border-right">{current?.phone}</td>
        <td className="border-right">
          {findFacility(current?._id ? current?._id : current?.id)}
        </td>
        <td className="border-right">
          <button
            className="btn btn-light btn-sm"
            onClick={openFacilityDropDown}
            title={totalCount + " passengers"}
          >
            <i
              className="fa fa-user"
              data-toggle="tooltip"
              // title="20 Passenger"
            ></i>{" "}
            <i
              className="fa fa-eye"
              data-toggle="modal"
              data-target="#realtedpass"
            ></i>
          </button>
        </td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
                to=""
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                {/* <button
                  onClick={() =>
                    navigate(`/passenger-view/${current.id}`, {
                      state: current,
                    })
                  }
                  className="dropdown-item"
                >
                  <i className="fa fa-eye"> View</i>
                </button> */}
                {auth?.user?.permissionId?.clients?.read === true && (
                  <button
                    onClick={() =>
                      navigate(`/facility/${current.id}`, {
                        state: current,
                      })
                    }
                    className="dropdown-item"
                  >
                    <i className="fa fa-eye"> View</i>
                  </button>
                )}
                {auth?.user?.permissionId?.clients?.update === true && (
                  <button className="dropdown-item" onClick={openDropDown}>
                    <i className="fa fa-edit"> Edit</i>
                  </button>
                )}
                {auth?.user?.permissionId?.clients?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={openDeleteDropDown}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </td>
        {showDropDown && (
          <AdminSettingPaymentAddCompanyModal
            data={current}
            showModal={showDropDown}
            setShowModal={openDropDown}
          />
        )}

        <AdminFacilityDelete
          current={current}
          showDeleteDropDown={showDeleteDropDown}
          openDeleteDropDown={openDeleteDropDown}
          index={i}
        />
        {showFacilityDropDown && (
          <AdminFacilityDetailsModals
            current={current}
            showFacilityDropDown={showFacilityDropDown}
            openFacilityDropDown={openFacilityDropDown}
            // index={i}
          />
        )}
      </tr>
    </>
  );
};
export default AdminFacilitiesViewTableRow;
