import React, { useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createNotificationDeveloper } from "../../actions/notificationDeveloper";

const SystemNotificationsAdd = () => {
  const notificationDeveloper = useSelector(
    (state) => state.notificationDeveloper
  );
  const auth = useSelector((state) => state.auth);
  const service = useSelector((state) => state.service);
  const [selectedValue, setSelectedValue] = useState([]);
  const { loading } = notificationDeveloper;

  const navigate = useNavigate();
  const [values, setValues] = useState({
    // companyId: Joi.string().custom(objectId).required(),
    subject: "",
    notificationsMessage: "",
    versionOfUpdates: "",
    date: "",
    startTime: "",
    endTime: "",
    timeZone: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  console.log(values, "vallll");

  const dispatch = useDispatch();
  const snData = {
    companyId: auth.user.companyId.id,
    subject: values.subject,
    notificationsMessage: values.notificationsMessage,
    versionOfUpdates: values.versionOfUpdates,
    date: values.date,
    startTime: values.startTime,
    endTime: values.endTime,
    timeZone: "GMT-6",
    // timeZone: values.timeZone,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    for (let key of Object.keys(snData)) {
      if (snData[key] === "") {
        delete snData[key];
      }
    }
    dispatch(createNotificationDeveloper(snData, navigate));
  };

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <section className="main-container" id="fullscreen">
        <div className="content-wrapper mt-3">
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white w-75 m-auto">
            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row pt-3 px-3 pb-0">
                <div className="col-lg-12">
                  <div className="bg-white  text-capitalize d-flex justify-content-between align-items-center  border-bottom-grey pb-3 px-2">
                    <h4 className="f-18 f-w-500 mb-0">
                      Add System Notification
                    </h4>
                    <div
                      className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                      role="group"
                    >
                      <div
                        className="btn-group me-2"
                        role="group"
                        aria-label="First group"
                      >
                        <Link
                          to="/system-notification"
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-eye d-none d-sm-inline-block"></i>{" "}
                          View Notification{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form className="row p-20" onSubmit={handleSubmit}>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      autocomplete="off"
                      value={values.subject}
                      onChange={handleChange("subject")}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group mb-1">
                    <label className="f-14 text-dark mb-12" htmlFor="Name">
                      Notification:
                    </label>
                  </div>
                  <div className="dropdown bootstrap-select form-control select-picker">
                    <textarea
                      rows="4"
                      cols="50"
                      className="form-control f-14"
                      value={values?.notificationsMessage}
                      onChange={handleChange("notificationsMessage")}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder="."
                      id="driver"
                      min="0"
                      autocomplete="off"
                      value={values.date}
                      onChange={handleChange("date")}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      Time Starts
                    </label>
                    <input
                      type="time"
                      className="form-control height-35 f-14"
                      placeholder="."
                      id="driver"
                      min="0"
                      autocomplete="off"
                      value={values.startTime}
                      onChange={handleChange("startTime")}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      End Time
                    </label>
                    <input
                      type="time"
                      className="form-control height-35 f-14"
                      placeholder="."
                      id="driver"
                      min="0"
                      autocomplete="off"
                      value={values.endTime}
                      onChange={handleChange("endTime")}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      Time zone (CST)
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="."
                      id="driver"
                      min="0"
                      autocomplete="off"
                      disabled
                      value="GMT-6"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      Version of the Updates
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="."
                      id="driver"
                      min="0"
                      autocomplete="off"
                      value={values.versionOfUpdates}
                      onChange={handleChange("versionOfUpdates")}
                      // required
                    />
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      for="clnumber"
                    >
                      Public Message:
                    </label>
                    <textarea rows="4" cols="50" className="form-control  f-14">
                      {" "}
                    </textarea>
                  </div>
                </div> */}
                <div className="col-md-12 pt-3 border-top">
                  <button type="submit" className="btn btn-primary px-5 ">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SystemNotificationsAdd;
