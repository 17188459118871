import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import defaultimg from "../../../assets/images/profile.jpg";
import { useLocation, useParams } from "react-router-dom";
import AdminPassengerEditNewModal from "./AdminPassengerEditNewModal";
import { getReservationsByCustomerId } from "../../../actions/reservationAction";
import moment from "moment";
// import AdminClientEditNewModal from "./AdminPassengerEditNewModal";
// import { getPassengersByCompanyIdWithPagination } from "../../../actions/userActions";

const AdminPassengerFullDetailComponent = () => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("Orders");

  const params = useParams();

  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const vehicle = useSelector((state) => state.vehicle);
  const { temp } = reservation;
  const [selectedImage, setSelectedImage] = useState(defaultimg);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getReservationsByCustomerId({
        companyId: auth.user.companyId.id,
        customerId: params?.id,
      })
    );
  }, []);
  // const userData = async (e) => {
  //   dispatch(
  //     getPassengersByCompanyIdWithPagination({
  //       companyId: auth.user.companyId.id,
  //       limit: limit,
  //       page: currentPageNumber,
  //     })
  //   );
  // };
  // useEffect(() => {
  //   if (auth.user !== null) {
  //   }
  // }, [auth.user]);

  // useEffect(() => {
  //   userData();
  // }, []);

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const facilityName = (id) => {
    const found = facility.find((x) => x.id == id || x._id == id);
    return found.name;
  };
  const handleTabClick = (tab2) => {
    setActiveTab(tab2);
  };
  return (
    <>
      <div className="row emp-dash-detail">
        <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
          <div className="card border-0 b-shadow-4">
            <div className="card-horizontal align-items-center">
              <div className="modal-body"></div>
              <div className="card-body border-0 pl-0">
                <div className="row">{/* row kam ki? */}</div>
                {auth?.user?.permissionId?.user?.update === true && (
                  <a className="edit-icon" onClick={openDropDown}>
                    <i className="fa fa-pencil"></i>
                  </a>
                )}
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Unique Id: </strong>
                        {state?.uniqueId}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Full Name: </strong>
                        {state?.firstName} {state?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0 f-14 text-capitalize">
                        <strong>Phone: </strong>
                        {state?.phone}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Email: </strong>
                        {state?.email}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 w-30 text-capitalize">
                        <strong>User Type: </strong>
                        {state?.role}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Address: </strong>
                        {state?.address}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Username: </strong>
                        {state?.username}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>City: </strong>
                        {state?.city}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>State: </strong>
                        {state?.state}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Facility: </strong>
                        {facilityName(state?.facility)}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Notes: </strong>
                        {state?.notes}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  {/* Tabs */}
                  {reservation.loading === false && temp?.length > 0 && (
                    <>
                      <div className="tabs2">
                        <div
                          className={`tab2 ${
                            activeTab === "Orders" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("Orders")}
                        >
                          Orders
                        </div>
                      </div>
                      {activeTab === "Orders" && (
                        <div
                          id="leads-table_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table border w-100 dataTable no-footer"
                                id="leads-table"
                                role="grid"
                                aria-describedby="leads-table_info"
                                style={{ overflowX: "auto" }}
                              >
                                <thead className="card-header">
                                  <tr role="row">
                                    <th
                                      title="Order ID"
                                      className="sorting border-right align-table-header min-width-orderId"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Mobile: activate to sort column ascending"
                                    >
                                      Order ID <br />
                                      {/* <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor("orderId", "asc"),
                                        }}
                                        onClick={() =>
                                          handleSortClick("orderId", "asc")
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor("orderId", "desc"),
                                        }}
                                        onClick={() =>
                                          handleSortClick("orderId", "desc")
                                        }
                                      ></i>
                                    </span> */}
                                    </th>
                                    <th
                                      title="Load#"
                                      className="sorting border-right align-table-header"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Mobile: activate to sort column ascending"
                                    >
                                      Load# <br />
                                      {/* <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor(
                                            "loadNumber",
                                            "asc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick("loadNumber", "asc")
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor(
                                            "loadNumber",
                                            "desc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick("loadNumber", "desc")
                                        }
                                      ></i>
                                    </span> */}
                                    </th>
                                    <th
                                      title="Driver Name"
                                      className="sorting border-right align-table-header min-width-orderId"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Mobile: activate to sort column ascending"
                                    >
                                      Driver Name{" "}
                                      {/* <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor(
                                            "driverId.firstName",
                                            "asc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick(
                                            "driverId.firstName",
                                            "asc"
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor(
                                            "driverId.firstName",
                                            "desc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick(
                                            "driverId.firstName",
                                            "desc"
                                          )
                                        }
                                      ></i>
                                    </span> */}
                                    </th>

                                    <th
                                      title="Vehicle#"
                                      className="sorting_disabled border-right align-table-header"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                    >
                                      Vehicle# <br />
                                      {/* <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor(
                                            "assetId.assetNo",
                                            "asc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick(
                                            "assetId.assetNo",
                                            "asc"
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor(
                                            "assetId.assetNo",
                                            "desc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick(
                                            "assetId.assetNo",
                                            "desc"
                                          )
                                        }
                                      ></i>
                                    </span> */}
                                    </th>
                                    <th
                                      title="Time"
                                      className="sorting border-right align-table-header"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Time <br />
                                      {/* <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor(
                                            "pickUpTime",
                                            "asc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick("pickUpTime", "asc")
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor(
                                            "pickUpTime",
                                            "desc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick("pickUpTime", "desc")
                                        }
                                      ></i>
                                    </span> */}
                                    </th>
                                    <th
                                      title="Pickup - Drop Off"
                                      className="sorting border-right w-30 align-table-header"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Pickup - Drop Off{" "}
                                    </th>
                                    <th
                                      title="Vehicle Type"
                                      className="sorting border-right align-table-header"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Vehicle Type{" "}
                                    </th>
                                    <th
                                      title="Status"
                                      className="sorting border-right align-table-header"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Status <br />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {temp &&
                                    temp?.length > 0 &&
                                    temp?.map((current, i) => {
                                      return (
                                        <tr
                                          role="row"
                                          // style={{
                                          //   boxShadow:
                                          //     current?.driverCancellation?.length >
                                          //       0 &&
                                          //     current?.status === "Requested" &&
                                          //     current.driverBookingStatus ===
                                          //       "Unassigned"
                                          //       ? "0 1px 10px 0 rgba(255, 0, 0, 0.3)" // Red shadow with 30% opacity
                                          //       : // ? "0 1px 10px 0 rgb(220, 20, 60)" // Red shadow with 30% opacity
                                          //         "", // Default shadow
                                          // }}
                                          className="odd border"
                                          key={i}
                                          // onMouseLeave={() =>
                                          //   setshowMoreVertDropDown(false)
                                          // }
                                        >
                                          <td className="border-right">
                                            {current?.orderId}
                                            &nbsp;
                                            {current?.isOnlineBooking ? (
                                              <>
                                                <br />
                                                <i
                                                  className="fa fa-globe"
                                                  title="Quote Booking"
                                                ></i>{" "}
                                                &nbsp;
                                                {current?.actions?.some(
                                                  (x) =>
                                                    x.action ===
                                                    "Send:Quotation"
                                                ) && (
                                                  <i
                                                    className="fa fa-share"
                                                    title="Quotation Sent"
                                                  ></i>
                                                )}
                                                &nbsp;
                                                {current?.actions?.some(
                                                  (x) =>
                                                    x.action === "Url:Viewed"
                                                ) && (
                                                  <i
                                                    className="fa fa-eye"
                                                    title="Customer viewed the link"
                                                  ></i>
                                                )}
                                                &nbsp;
                                                {current?.isPaid && (
                                                  <i
                                                    className="fa fa-money"
                                                    title="Paid"
                                                  ></i>
                                                )}
                                                {current?.actions?.some(
                                                  (x) =>
                                                    x.action ===
                                                    "Create:StandingOrder"
                                                ) && (
                                                  <i
                                                    className="fa fa-repeat"
                                                    title="Standing Order"
                                                  ></i>
                                                )}
                                              </>
                                            ) : current?.isQuote ? (
                                              <>
                                                <br />
                                                <i
                                                  className="fa fa-clipboard"
                                                  title="Quotation"
                                                ></i>{" "}
                                                &nbsp;
                                                {current?.actions?.some(
                                                  (x) =>
                                                    x.action ===
                                                    "Send:Quotation"
                                                ) && (
                                                  <i
                                                    className="fa fa-share"
                                                    title="Quotation Sent"
                                                  ></i>
                                                )}
                                                &nbsp;
                                                {current?.actions?.some(
                                                  (x) =>
                                                    x.action === "Url:Viewed"
                                                ) && (
                                                  <i
                                                    className="fa fa-eye"
                                                    title="Customer viewed the link"
                                                  ></i>
                                                )}
                                                &nbsp;
                                                {current?.isPaid && (
                                                  <i
                                                    className="fa fa-money"
                                                    title="Paid"
                                                  ></i>
                                                )}
                                                {current?.actions?.some(
                                                  (x) =>
                                                    x.action ===
                                                    "Create:StandingOrder"
                                                ) && (
                                                  <i
                                                    className="fa fa-repeat"
                                                    title="Standing Order"
                                                  ></i>
                                                )}
                                              </>
                                            ) : (
                                              current?.actions?.some(
                                                (x) =>
                                                  x.action ===
                                                  "Create:StandingOrder"
                                              ) && (
                                                <>
                                                  <br />
                                                  <i
                                                    className="fa fa-repeat"
                                                    title="Standing Order"
                                                  ></i>
                                                </>
                                              )
                                            )}
                                            <br />
                                            {current?.connectedReservation &&
                                              current?.connectedReservation
                                                ?.type +
                                                ":" +
                                                current?.connectedReservation
                                                  ?.reservationId?.orderId}
                                          </td>
                                          <td className="border-right">
                                            {current?.specialNumber
                                              ? current.specialNumber
                                              : current?.loadNumber
                                              ? current.loadNumber
                                              : ""}
                                          </td>
                                          <td
                                            className="border-right"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {current?.driverId
                                              ? current?.driverId?.firstName +
                                                " " +
                                                current?.driverId?.lastName
                                              : "-"}
                                            {current?.assetId?.badgeId &&
                                              vehicle?.allBadges &&
                                              current?.assetId?.badgeId.map(
                                                (badgeId) => {
                                                  const matchingBadge =
                                                    vehicle.allBadges.find(
                                                      (badge) =>
                                                        badge.id === badgeId
                                                    );
                                                  return matchingBadge ? (
                                                    <img
                                                      key={badgeId}
                                                      className="inline-block"
                                                      src={matchingBadge.badge}
                                                      alt={`Badge ${badgeId}`}
                                                      style={{
                                                        width: "25px",
                                                        borderRadius: "50%",
                                                        height: "25px",
                                                        objectFit: "cover",
                                                        marginRight: "5px",
                                                        marginTop: "3px",
                                                      }}
                                                    />
                                                  ) : (
                                                    "-"
                                                  );
                                                }
                                              )}
                                          </td>
                                          <td className="border-right">
                                            {current?.assetId?.assetNo}
                                            <br />
                                            {vehicle.vehicle.map((x) => {
                                              let assetId = current?.assetId
                                                ?._id
                                                ? current?.assetId?._id
                                                : current?.assetId?.id;
                                              if (x?.id === assetId) {
                                                return (
                                                  <i
                                                    className="fa fa-exclamation-circle fa-w-16 text-info"
                                                    title={`${x?.make}, ${x?.model}, ${x?.color}`} // Display make, model, color
                                                    data-toggle="modal"
                                                    data-target="#myDriver"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    key={x.id} // Ensure unique key for React list rendering
                                                  ></i>
                                                );
                                              }
                                              return null; // Return null if no match
                                            })}
                                            <br />
                                          </td>
                                          <td className="border-right">
                                            <strong>Pickup:</strong>{" "}
                                            {parseInt(
                                              current?.pickUpTime?.substring(
                                                0,
                                                2
                                              )
                                            ) === 0
                                              ? 12
                                              : parseInt(
                                                  current?.pickUpTime?.substring(
                                                    0,
                                                    2
                                                  )
                                                ) > 12
                                              ? parseInt(
                                                  current?.pickUpTime?.substring(
                                                    0,
                                                    2
                                                  )
                                                ) - 12
                                              : parseInt(
                                                  current?.pickUpTime?.substring(
                                                    0,
                                                    2
                                                  )
                                                )}
                                            {":" +
                                              current?.pickUpTime?.substring(
                                                3,
                                                5
                                              )}
                                            {parseInt(
                                              current?.pickUpTime?.substring(
                                                0,
                                                2
                                              )
                                            ) > 11
                                              ? "pm"
                                              : "am"}
                                            <br />
                                            <strong>Dropoff:</strong>{" "}
                                            {parseInt(
                                              current?.dropOffTime?.substring(
                                                0,
                                                2
                                              )
                                            ) === 0
                                              ? 12
                                              : parseInt(
                                                  current?.dropOffTime?.substring(
                                                    0,
                                                    2
                                                  )
                                                ) > 12
                                              ? parseInt(
                                                  current?.dropOffTime?.substring(
                                                    0,
                                                    2
                                                  )
                                                ) - 12
                                              : parseInt(
                                                  current?.dropOffTime?.substring(
                                                    0,
                                                    2
                                                  )
                                                )}
                                            {":" +
                                              current?.dropOffTime?.substring(
                                                3,
                                                5
                                              )}
                                            {parseInt(
                                              current?.dropOffTime?.substring(
                                                0,
                                                2
                                              )
                                            ) > 11
                                              ? "pm"
                                              : "am"}
                                            <br />
                                            <strong>ETA:</strong>{" "}
                                            {
                                              current?.fareAndDistance
                                                ?.estimatedTime
                                            }
                                          </td>
                                          <td className="border-right w-30">
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <div>
                                                  <p className="f-12 font-medium mb-0">
                                                    <strong>
                                                      {
                                                        current?.pickUpAddress
                                                          ?.address
                                                      }
                                                    </strong>{" "}
                                                  </p>
                                                </div>
                                                <div>
                                                  <time>
                                                    {" "}
                                                    {moment(
                                                      current?.pickUpDate?.substring(
                                                        0,
                                                        10
                                                      )
                                                    ).format("MM/DD/YYYY")}{" "}
                                                    -{" "}
                                                    {parseInt(
                                                      current.pickUpTime?.substring(
                                                        0,
                                                        2
                                                      )
                                                    ) === 0
                                                      ? 12
                                                      : parseInt(
                                                          current.pickUpTime?.substring(
                                                            0,
                                                            2
                                                          )
                                                        ) > 12
                                                      ? parseInt(
                                                          current.pickUpTime?.substring(
                                                            0,
                                                            2
                                                          )
                                                        ) - 12
                                                      : parseInt(
                                                          current.pickUpTime?.substring(
                                                            0,
                                                            2
                                                          )
                                                        )}
                                                    {":" +
                                                      current.pickUpTime?.substring(
                                                        3,
                                                        5
                                                      )}
                                                    {parseInt(
                                                      current?.pickUpTime?.substring(
                                                        0,
                                                        2
                                                      )
                                                    ) > 11
                                                      ? "pm"
                                                      : "am"}
                                                  </time>
                                                </div>
                                              </div>
                                              <div className="mt-4 px-4">
                                                <div className="hidden sm:block">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="50"
                                                    height="8"
                                                    viewBox="0 0 40 6"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                                      fill="black"
                                                    ></path>
                                                  </svg>
                                                </div>
                                                <p className="text-center font-medium">
                                                  <span>
                                                    {current?.fareAndDistance
                                                      ?.apiDistanceFromPickToDropOff
                                                      ? `${current?.fareAndDistance?.apiDistanceFromPickToDropOff.toFixed(
                                                          2
                                                        )} mi`
                                                      : `${current?.fareAndDistance?.distanceFromPickToDropOff?.toFixed(
                                                          2
                                                        )} mi`}
                                                  </span>
                                                </p>
                                              </div>
                                              <div>
                                                <div>
                                                  <p className="f-12 font-medium mb-0">
                                                    <strong>
                                                      {
                                                        current?.dropOffAddress
                                                          ?.address
                                                      }
                                                    </strong>
                                                  </p>
                                                </div>
                                                <div>
                                                  <time>
                                                    {" "}
                                                    {moment(
                                                      current?.dropOffDate?.substring(
                                                        0,
                                                        10
                                                      )
                                                    ).format("MM/DD/YYYY")}{" "}
                                                    -{" "}
                                                    {parseInt(
                                                      current.dropOffTime?.substring(
                                                        0,
                                                        2
                                                      )
                                                    ) === 0
                                                      ? 12
                                                      : parseInt(
                                                          current.dropOffTime?.substring(
                                                            0,
                                                            2
                                                          )
                                                        ) > 12
                                                      ? parseInt(
                                                          current.dropOffTime?.substring(
                                                            0,
                                                            2
                                                          )
                                                        ) - 12
                                                      : parseInt(
                                                          current.dropOffTime?.substring(
                                                            0,
                                                            2
                                                          )
                                                        )}
                                                    {":" +
                                                      current.dropOffTime?.substring(
                                                        3,
                                                        5
                                                      )}
                                                    {parseInt(
                                                      current.dropOffTime?.substring(
                                                        0,
                                                        2
                                                      )
                                                    ) > 11
                                                      ? "pm"
                                                      : "am"}
                                                  </time>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="border-right">
                                            <strong>
                                              {current?.type?.name}
                                            </strong>
                                            {current?.serviceName && (
                                              <i
                                                className="fa fa-exclamation-circle fa-w-16 text-info"
                                                title={current?.serviceName}
                                                data-toggle="modal"
                                                data-target="#myDriver"
                                                style={{ cursor: "pointer" }}
                                              ></i>
                                            )}{" "}
                                            <br />
                                          </td>
                                          <td className="border-right">
                                            {current?.status === "In Progress"
                                              ? current?.actions
                                                  ?.slice()
                                                  .reverse()
                                                  .find(({ action }) =>
                                                    [
                                                      "start",
                                                      "arrive",
                                                      "pickup",
                                                    ].includes(action)
                                                  )?.action === "start"
                                                ? "IP: Towards Pick-up"
                                                : current?.actions
                                                    ?.slice()
                                                    .reverse()
                                                    .find(({ action }) =>
                                                      [
                                                        "arrive",
                                                        "pickup",
                                                      ].includes(action)
                                                    )?.action === "arrive"
                                                ? "IP: Arrived"
                                                : current?.actions
                                                    ?.slice()
                                                    .reverse()
                                                    .find(
                                                      ({ action }) =>
                                                        action === "pickup"
                                                    )?.action === "pickup"
                                                ? "IP: Towards Dropoff"
                                                : current?.status
                                              : current?.status}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* {reservation &&
                          reservation.reservation &&
                          reservation.reservation.today &&
                          reservation.reservation.today.length > 0 &&
                          paginatedData?.results?.length > 0 && (
                            <div className="d-flex ">
                              {reservation.loading === false ? (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing{" "}
                                    {1 +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    to{" "}
                                    {paginatedData.displayingResults +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    of {paginatedData.totalResults} entries
                                  </div>
                                </div>
                              ) : (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Loading entries
                                  </div>
                                </div>
                              )}
                              <div className="search-col-table">
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="leads-table_paginate"
                                >
                                  <ul className="pagination">
                                    <li className="page-item">
                                      <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Previous"
                                        // onClick={onClickPrev}
                                        onClick={(e) =>
                                          handlePagination(
                                            currentPage - 1,
                                            sortField,
                                            sortOrder,
                                            searchTerm,
                                            limit
                                          )
                                        }
                                      >
                                        <span aria-hidden="true">Previous</span>
                                        <span className="sr-only">Previous</span>
                                      </NavLink>
                                    </li>
                                    {reservation &&
                                      reservation.reservation &&
                                      reservation.reservation.today &&
                                      paginatedData.paginationArr &&
                                      paginatedData.paginationArr.length > 0 &&
                                      // reservation.reservation.previous.paginationArr.map((current, i) => {
                                      // reservation.reservation.previous.paginationArr.slice(startIndex, endIndex).map((current, i) => {
                                      Array.from(
                                        {
                                          length:
                                            paginatedData.endPage -
                                            paginatedData.startPage +
                                            1,
                                        },
                                        (_, i) => {
                                          const current =
                                            paginatedData.startPage + i;
                                          return paginatedData?.currentPageNumber ===
                                            current ? (
                                            <li
                                              className="page-item active"
                                              key={i}
                                            >
                                              <Link
                                                to=""
                                                className="page-link"
                                                value={current}
                                              >
                                                {current}
                                              </Link>
                                            </li>
                                          ) : (
                                            <li className="page-item" key={i}>
                                              <Link
                                                className="page-link"
                                                value={current}
                                                onClick={(e) =>
                                                  handlePagination(
                                                    parseInt(current),
                                                    sortField,
                                                    sortOrder,
                                                    searchTerm,
                                                    limit
                                                  )
                                                }
                                                to=""
                                              >
                                                {current}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    <li className="page-item">
                                      <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Next"
                                        // onClick={onClickNext}
                                        onClick={(e) =>
                                          handlePagination(
                                            currentPage + 1,
                                            sortField,
                                            sortOrder,
                                            searchTerm,
                                            limit
                                          )
                                        }
                                      >
                                        <span aria-hidden="true">Next</span>
                                        <span className="sr-only">Next</span>
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminPassengerEditNewModal
        current={state}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default AdminPassengerFullDetailComponent;
