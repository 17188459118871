/** @format */

import React, { useEffect, useRef, useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminDailyActivityReportTable from "../../components/adminComponents/adminReportComponents/AdminDailyActivityReportTable";
import AdminDailyActivityReportSearchTable from "../../components/adminComponents/adminReportComponents/AdminDailyActivityReportSearchTable";
import AdminReportSearchandFilterV2 from "../../components/adminComponents/adminReportComponents/AdminReportSearchandFilterV2";
import Chart from "chart.js/auto";
import axios from "axios";
import { SERVERADDRESS } from "../../constants";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ExcelExport from "../../ExcelExport";

function AdminPercentageReportPage() {
  // const auth = useSelector((state) => state.auth);
  // const [revenue, setRevenue] = useState(null);

  // const chicagoDateOptions = {
  //   timeZone: "America/Chicago",
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // };

  // const chicagoDate = new Date().toLocaleDateString(
  //   "en-US",
  //   chicagoDateOptions
  // );
  // const currentDate = new Date(chicagoDate);
  // // Adjust to get the Monday of the current week
  // const firstDayOfWeek = new Date(currentDate);
  // firstDayOfWeek.setDate(
  //   currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
  // );

  // // Calculate the Sunday of the current week
  // const lastDayOfWeek = new Date(firstDayOfWeek);
  // lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  // // Format the dates to YYYY-MM-DD
  // const weekFirst = `${firstDayOfWeek.getFullYear()}-${String(
  //   firstDayOfWeek.getMonth() + 1
  // ).padStart(2, "0")}-${String(firstDayOfWeek.getDate()).padStart(2, "0")}`;
  // const weekLast = `${lastDayOfWeek.getFullYear()}-${String(
  //   lastDayOfWeek.getMonth() + 1
  // ).padStart(2, "0")}-${String(lastDayOfWeek.getDate()).padStart(2, "0")}`;

  // const [values, setValues] = useState({
  //   // driverId: "",
  //   companyId: auth.user.companyId.id,
  //   from: weekFirst,
  //   to: weekLast,
  // });
  // const handleChange = (fieldName) => (event) => {
  //   setValues({
  //     ...values,
  //     [fieldName]: event.target.value,
  //   });
  // };
  // useEffect(() => {
  //   // Data for the chart
  //   // const xValues1 = ["Driver Amount", "Company Amount"];
  //   // // const yValues1 = [55, 49, 44, 25];
  //   // const barColors1 = ["#b91d47", "#1e7145"];

  //   // const fetchData = async () => {
  //   //   try {
  //   //     const { data } = await axios.post(
  //   //       `${SERVERADDRESS}/v1/kpi/percantageReport`,
  //   //       values
  //   //     );
  //   //     setRevenue(data);
  //   //     // Get the canvas element
  //   //     const ctx1 = document.getElementById("myChart1");
  //   //     // Create the chart
  //   //     new Chart(ctx1, {
  //   //       type: "pie",
  //   //       data: {
  //   //         labels: xValues1,
  //   //         datasets: [
  //   //           {
  //   //             backgroundColor: barColors1,
  //   //             data: [data.driverAmount, data.companyAmount],
  //   //           },
  //   //         ],
  //   //       },
  //   //       options: {
  //   //         title: {
  //   //           display: true,
  //   //           text: "CCT Revenue",
  //   //         },
  //   //       },
  //   //     });
  //   //   } catch (error) {
  //   //     console.error("Error fetching data:", error);
  //   //   }
  //   // };
  //   handleClick();
  //   // Get the canvas element
  //   // const ctx1 = document.getElementById("myChart1");

  //   // // Create the chart
  //   // new Chart(ctx1, {
  //   //   type: "pie",
  //   //   data: {
  //   //     labels: xValues1,
  //   //     datasets: [
  //   //       {
  //   //         backgroundColor: barColors1,
  //   //         data: yValues1,
  //   //       },
  //   //     ],
  //   //   },
  //   //   options: {
  //   //     title: {
  //   //       display: true,
  //   //       text: "World Wide Wine Production 2018",
  //   //     },
  //   //   },
  //   // });
  //   // fetchData();
  // }, []);

  // useEffect(() => {
  //   if (!revenue) {
  //     const xValues1 = ["Driver Amount", "Company Amount"];
  //     // const yValues1 = [55, 49, 44, 25];
  //     const barColors1 = ["#b91d47", "#1e7145"];
  //     const ctx1 = document.getElementById("myChart1");
  //     // Create the chart
  //     new Chart(ctx1, {
  //       type: "pie",
  //       data: {
  //         labels: xValues1,
  //         datasets: [
  //           {
  //             backgroundColor: barColors1,
  //             data: [revenue?.driverAmount, revenue?.companyAmount],
  //           },
  //         ],
  //       },
  //       options: {
  //         title: {
  //           display: true,
  //           text: "CCT Revenue",
  //         },
  //       },
  //     });
  //   }
  // }, [revenue]);

  // const handleClick = async () => {
  //   try {
  //     const xValues1 = ["Driver Amount", "Company Amount"];
  //     // const yValues1 = [55, 49, 44, 25];
  //     const barColors1 = ["#b91d47", "#1e7145"];
  //     const { data } = await axios.post(
  //       `${SERVERADDRESS}/v1/kpi/percantageReport`,
  //       values
  //     );
  //     setRevenue(data);
  //     // Get the canvas element
  //     // const ctx1 = document.getElementById("myChart1");
  //     // // Create the chart
  //     // new Chart(ctx1, {
  //     //   type: "pie",
  //     //   data: {
  //     //     labels: xValues1,
  //     //     datasets: [
  //     //       {
  //     //         backgroundColor: barColors1,
  //     //         data: [data.driverAmount, data.companyAmount],
  //     //       },
  //     //     ],
  //     //   },
  //     //   options: {
  //     //     title: {
  //     //       display: true,
  //     //       text: "CCT Revenue",
  //     //     },
  //     //   },
  //     // });
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const auth = useSelector((state) => state.auth);
  const [revenue, setRevenue] = useState(null);
  const [values, setValues] = useState({
    companyId: auth.user.companyId.id,
    from: "",
    to: "",
  });

  const chartRef = useRef(null);

  const handleClick = async () => {
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/kpi/percantageReport`,
        values
      );
      setRevenue(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (revenue) {
      const xValues1 = ["Driver Amount", "Company Amount"];
      const barColors1 = ["#b91d47", "#1e7145"];

      // Destroy previous chart instance if exists
      if (chartRef.current !== null) {
        chartRef.current.destroy();
      }

      const ctx1 = document.getElementById("myChart1");
      chartRef.current = new Chart(ctx1, {
        type: "pie",
        data: {
          labels: xValues1,
          datasets: [
            {
              backgroundColor: barColors1,
              data: [revenue.driverAmount, revenue.companyAmount],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "CCT Revenue",
            },
          },
        },
      });
    }
  }, [revenue]);

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const locationUrl = useLocation();

  const data = [
    {
      "Company Amount": revenue?.companyAmount,
      "Driver Amount": revenue?.driverAmount,
      "Total Income": revenue?.totalAmount,
      Percentage: revenue?.companyPercentage,
    },
  ];
  const data2 = [
    {
      "Company Amount": revenue?.companyAmount,
      "Driver Amount": revenue?.driverAmount,
      "Total Income": revenue?.totalAmount,
      "Profit/Loss": revenue?.companyAmount,
    },
  ];

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {locationUrl.pathname === "/percentage-report"
                    ? "Percentage Report"
                    : "Profit/Loss Report"}
                </h4>
              </div>
            </div>

            <div className="card mb-3">
              <div
                className="card-header"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className="f-18 font-weight-bold text-dark mx-1 ">
                  Generate Profit And Loss Statement For A Specific Period.
                </span>
                <div
                  className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                  role="group"
                >
                  <ExcelExport
                    excelData={
                      locationUrl.pathname === "/percentage-report"
                        ? data
                        : data2
                    }
                    fileName={
                      locationUrl.pathname === "/percentage-report"
                        ? "Percentage Report"
                        : "Profit/Loss Report"
                    }
                  />
                </div>
              </div>
              <div className="card-body p-0 ">
                <div className="row p-2 px-4">
                  <div className="col">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.from}
                        onChange={handleChange("from")}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        To
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.to}
                        onChange={handleChange("to")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-auto mt-0 mt-sm-3">
                    <div className="d-grid mt-3">
                      <button
                        className="btn btn-success"
                        data-toggle="modal"
                        data-target="#generatedData"
                        aria-hidden="true"
                        onClick={handleClick}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {revenue && (
              <>
                <div className="row d-flex align-items-stretch">
                  <div className="col-md-7">
                    <div className="card h-100">
                      <div className="card-header justify-content-between">
                        <div className="f-18 text-dark mx-1 heavy bolder">
                          Statement From:{" "}
                          {revenue
                            ? moment(values.from).format("MMM DD, YYYY") +
                              " to " +
                              moment(values.to).format("MMM DD, YYYY")
                            : ""}
                        </div>
                        <div
                          className="f-17 text-dark mx-1"
                          style={{ opacity: "0.4" }}
                        >
                          {locationUrl.pathname === "/percentage-report"
                            ? "Details of Load-Percentage profit and loss statement for selected period"
                            : "Details of profit and loss statement for selected period"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row mt-3 pb-3">
                            <div className="form-check">
                              <h4 className="text-dark mx-1">Income</h4>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "90%",
                              border: "1px solid",
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "0px 25px",
                            }}
                          >
                            <div style={{ padding: "25px" }}>
                              {" "}
                              Company Amount
                            </div>
                            <div style={{ padding: "25px" }}>
                              ${revenue.companyAmount}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "90%",
                              border: "1px solid",
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "0px 25px",
                            }}
                          >
                            <div style={{ padding: "25px" }}>
                              {" "}
                              Driver Amount
                            </div>
                            <div style={{ padding: "25px" }}>
                              ${revenue.driverAmount}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "90%",
                              border: "1px solid",
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "0px 25px",
                            }}
                          >
                            <div style={{ padding: "25px" }}> Total Income</div>
                            <div style={{ padding: "25px" }}>
                              ${revenue.totalAmount}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            style={{
                              width: "90%",
                              border: "1px solid",
                              display: "flex",
                              justifyContent: "space-evenly",
                              margin: "25px 25px",
                            }}
                          >
                            <div style={{ padding: "25px" }}>
                              {locationUrl.pathname === "/percentage-report"
                                ? "Percentage"
                                : "Profit/Loss"}
                            </div>
                            <div style={{ padding: "25px" }}>
                              {locationUrl.pathname === "/percentage-report"
                                ? revenue.companyPercentage + "%"
                                : "$" + revenue.companyAmount}
                            </div>
                          </div>
                        </div>
                        {/* another heading */}
                        {/* <div className="col-md-12">
                      <div className="row mt-3 pb-3">
                        <div className="form-check">
                          <h4 className="text-dark mx-1">Expenses</h4>
                        </div>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                  <div className="col-md-5">
                    <div className="b-shadow-4 rounded p-2 bg-col-new h-100">
                      <div
                        className="card-body p-2 clearfix"
                        style={{ minHeight: "240px" }}
                      >
                        <div className="driver-text mb-3">
                          <div className="f-18 text-dark mx-1 heavy bolder">
                            Profit & Loss Summary
                          </div>
                          <div
                            className="f-17 text-dark mx-1"
                            style={{ opacity: "0.4" }}
                          >
                            Graphical representation of statement from selected
                            period
                          </div>
                        </div>
                        <canvas
                          id="myChart1"
                          style={{ width: "100%", maxWidth: "600px" }}
                        ></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <br />
            <div className="row">
              {revenue?.groupByTypes?.length > 0 &&
                revenue?.groupByTypes?.map((current, index) => {
                  return (
                    <div className="col-md-6" key={index}>
                      <div className="card mb-4">
                        <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                          <span>{current?._id}</span>
                          <div>
                            <span>
                              Total Amount: $
                              {current?.totalAmount
                                ? current?.totalAmount?.toFixed(2)
                                : ""}
                            </span>
                            <span className="badge badge-light ml-2">
                              Total Count: {current?.count}
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-group">
                            {revenue?.groupByServices?.length > 0 &&
                              revenue?.groupByServices?.map((x, i) => {
                                return (
                                  x?._id.type === current?._id && (
                                    <li
                                      className="list-group-item d-flex justify-content-between align-items-center"
                                      key={i}
                                    >
                                      <span>{x?._id?.serviceName}</span>
                                      <div>
                                        <span className="badge badge-primary">
                                          $
                                          {x?.totalAmount
                                            ? x?.totalAmount?.toFixed(2)
                                            : ""}
                                        </span>
                                        <span className="badge badge-light">
                                          Count: {x?.count}
                                        </span>
                                      </div>
                                    </li>
                                  )
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* <div className="card mb-4">
                <div className="card-header bg-secondary text-white d-flex justify-content-between align-items-center">
                  <span>Wheelchair</span>
                  <div>
                    <span>Total Amount: $14,402.96</span>
                    <span className="badge badge-light ml-2">Total Count: 126</span>
                  </div>
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>RTN - Wheelchair</span>
                      <div>
                        <span className="badge badge-secondary">$257.26</span>
                        <span className="badge badge-light">Count: 2</span>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>WAV</span>
                      <div>
                        <span className="badge badge-secondary">$2360.01</span>
                        <span className="badge badge-light">Count: 19</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
export default AdminPercentageReportPage;
