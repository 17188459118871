import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getFacilityById,
  getPassengersByCompanyIdWithPagination,
} from "../../../actions/userActions";
import defaultimg from "../../../assets/images/profile.jpg";
import { useLocation, useParams } from "react-router-dom";
// import AdminClientEditNewModal from "./AdminPassengerEditNewModal";
import AdminPassengerEditNewModal from "./AdminPassengerEditNewModal";
import { getReservationsByCustomerId } from "../../../actions/reservationAction";
import moment from "moment";

const AdminFacilityDetailComponent = () => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState("Orders");

  const params = useParams();

  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const vehicle = useSelector((state) => state.vehicle);
  const { temp } = students;
  const [selectedImage, setSelectedImage] = useState(defaultimg);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getFacilityById({
        _id: params?.id,
      })
    );
  }, []);
  // const userData = async (e) => {
  //   dispatch(
  //     getPassengersByCompanyIdWithPagination({
  //       companyId: auth.user.companyId.id,
  //       limit: limit,
  //       page: currentPageNumber,
  //     })
  //   );
  // };
  // useEffect(() => {
  //   if (auth.user !== null) {
  //   }
  // }, [auth.user]);

  // useEffect(() => {
  //   userData();
  // }, []);

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const facilityName = (id) => {
    const found = facility.find((x) => x.id == id || x._id == id);
    return found.name;
  };
  const handleTabClick = (tab2) => {
    setActiveTab(tab2);
  };
  return (
    <>
      <div className="row emp-dash-detail">
        <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
          <div className="card border-0 b-shadow-4">
            <div className="card-horizontal align-items-center">
              {/* <div className="modal-body"></div> */}
              <div className="card-body border-0 pl-0">
                <div className="row">{/* row kam ki? */}</div>
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Name: </strong>
                        {state?.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0 f-14 text-capitalize">
                        <strong>Phone: </strong>
                        {state?.phone}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Email: </strong>
                        {state?.email}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Description: </strong>
                        {state?.description ? state.description : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  {/* Tabs */}
                  {students.loading === false &&
                    temp?.passenger?.length > 0 && (
                      <>
                        <div className="tabs2">
                          <div
                            className={`tab2 ${
                              activeTab === "Orders" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Orders")}
                          >
                            Passengers: {temp?.passenger?.length}
                          </div>
                        </div>
                        {activeTab === "Orders" && (
                          <div
                            id="leads-table_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  className="table border w-100 dataTable no-footer"
                                  id="leads-table"
                                  role="grid"
                                  aria-describedby="leads-table_info"
                                  style={{ overflowX: "auto" }}
                                >
                                  <thead className="card-header">
                                    <tr role="row">
                                      <th
                                        title="Unique Id"
                                        className="sorting border-right align-table-header min-width-orderId"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Mobile: activate to sort column ascending"
                                      >
                                        Unique Id
                                      </th>
                                      <th
                                        title="Name"
                                        className="sorting border-right"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Mobile: activate to sort column ascending"
                                      >
                                        Name
                                      </th>
                                      <th
                                        title="Email"
                                        className="sorting border-right"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Lead Agent: activate to sort column ascending"
                                      >
                                        Email
                                      </th>
                                      <th
                                        title="Phone"
                                        className="sorting border-right"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Lead Agent: activate to sort column ascending"
                                      >
                                        Phone
                                      </th>
                                      <th
                                        title="Notes"
                                        className="sorting border-right"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Lead Agent: activate to sort column ascending"
                                      >
                                        Notes
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {temp &&
                                      temp?.passenger?.length > 0 &&
                                      temp?.passenger?.map((current, i) => {
                                        return (
                                          <tr
                                            id="row-20"
                                            role="row"
                                            className="odd border"
                                            key={i}
                                            // onMouseLeave={() => setshowMoreVertDropDown(false)}
                                          >
                                            <td className="border-right">
                                              {current.uniqueId}
                                            </td>
                                            <td className="border-right">
                                              {current.firstName}{" "}
                                              {current.lastName}
                                            </td>
                                            <td className="border-right">
                                              {current.email}
                                            </td>
                                            <td className="border-right">
                                              {current.phone}
                                            </td>
                                            <td className="border-right">
                                              {current.notes}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* {reservation &&
                          reservation.reservation &&
                          reservation.reservation.today &&
                          reservation.reservation.today.length > 0 &&
                          paginatedData?.results?.length > 0 && (
                            <div className="d-flex ">
                              {reservation.loading === false ? (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing{" "}
                                    {1 +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    to{" "}
                                    {paginatedData.displayingResults +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    of {paginatedData.totalResults} entries
                                  </div>
                                </div>
                              ) : (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Loading entries
                                  </div>
                                </div>
                              )}
                              <div className="search-col-table">
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="leads-table_paginate"
                                >
                                  <ul className="pagination">
                                    <li className="page-item">
                                      <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Previous"
                                        // onClick={onClickPrev}
                                        onClick={(e) =>
                                          handlePagination(
                                            currentPage - 1,
                                            sortField,
                                            sortOrder,
                                            searchTerm,
                                            limit
                                          )
                                        }
                                      >
                                        <span aria-hidden="true">Previous</span>
                                        <span className="sr-only">Previous</span>
                                      </NavLink>
                                    </li>
                                    {reservation &&
                                      reservation.reservation &&
                                      reservation.reservation.today &&
                                      paginatedData.paginationArr &&
                                      paginatedData.paginationArr.length > 0 &&
                                      // reservation.reservation.previous.paginationArr.map((current, i) => {
                                      // reservation.reservation.previous.paginationArr.slice(startIndex, endIndex).map((current, i) => {
                                      Array.from(
                                        {
                                          length:
                                            paginatedData.endPage -
                                            paginatedData.startPage +
                                            1,
                                        },
                                        (_, i) => {
                                          const current =
                                            paginatedData.startPage + i;
                                          return paginatedData?.currentPageNumber ===
                                            current ? (
                                            <li
                                              className="page-item active"
                                              key={i}
                                            >
                                              <Link
                                                to=""
                                                className="page-link"
                                                value={current}
                                              >
                                                {current}
                                              </Link>
                                            </li>
                                          ) : (
                                            <li className="page-item" key={i}>
                                              <Link
                                                className="page-link"
                                                value={current}
                                                onClick={(e) =>
                                                  handlePagination(
                                                    parseInt(current),
                                                    sortField,
                                                    sortOrder,
                                                    searchTerm,
                                                    limit
                                                  )
                                                }
                                                to=""
                                              >
                                                {current}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    <li className="page-item">
                                      <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Next"
                                        // onClick={onClickNext}
                                        onClick={(e) =>
                                          handlePagination(
                                            currentPage + 1,
                                            sortField,
                                            sortOrder,
                                            searchTerm,
                                            limit
                                          )
                                        }
                                      >
                                        <span aria-hidden="true">Next</span>
                                        <span className="sr-only">Next</span>
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )} */}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminPassengerEditNewModal
        current={state}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default AdminFacilityDetailComponent;
