/** @format */

import {
  GET_STUDENTS_FAIL,
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  STUDENT_CREATE_FAIL,
  STUDENT_CREATE_REQUEST,
  STUDENT_CREATE_SUCCESS,
  GET_ROUTE_PASSENGERS_FAIL,
  GET_ROUTE_PASSENGERS_REQUEST,
  GET_ROUTE_PASSENGERS_SUCCESS,
  ROUTE_PASSENGER_CREATE_FAIL,
  ROUTE_PASSENGER_CREATE_REQUEST,
  ROUTE_PASSENGER_CREATE_SUCCESS,
  PASSENGER_UPDATE_REQUEST,
  PASSENGER_UPDATE_SUCCESS,
  PASSENGER_UPDATE_FAIL,
  PASSENGER_DELETE_REQUEST,
  PASSENGER_DELETE_SUCCESS,
  PASSENGER_DELETE_FAIL,
  FACILITY_TEMP_FAIL,
  FACILITY_TEMP_REQUEST,
  FACILITY_TEMP_SUCCESS,
} from "../constants";

export const studentsReducer = (
  state = {
    list: [],
    routePassengerList: [],
    students: null,
    temp: null,
  },
  action
) => {
  switch (action.type) {
    // ALL STUDENTS
    case GET_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_STUDENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // STUDENT CREATE
    case STUDENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STUDENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      };
    case STUDENT_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ALL ROUTE PASSENGERS
    case GET_ROUTE_PASSENGERS_REQUEST:
      return {
        ...state,
      };
    case GET_ROUTE_PASSENGERS_SUCCESS:
      return {
        ...state,
        routePassengerList: action.payload,
      };
    case GET_ROUTE_PASSENGERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // ROUTE_PASSENGER CREATE
    case ROUTE_PASSENGER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROUTE_PASSENGER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        routePassengerList: [...state.routePassengerList, action.payload],
      };
    case ROUTE_PASSENGER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // PASSENGER_ UPDATE
    case PASSENGER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PASSENGER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        // students: {
        //   ...state.students,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
        routePassengerList: state.routePassengerList.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
        // },
      };
    case PASSENGER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // PASSENGER_ DELETE
    case PASSENGER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PASSENGER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        // students: {
        //   ...state.students,
        list: state.list.filter((x) => x.id !== action.payload.id),
        routePassengerList: state.routePassengerList.filter(
          (x) => x.id !== action.payload.id
        ),
        // },
      };
    case PASSENGER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ALL STUDENTS
    case FACILITY_TEMP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FACILITY_TEMP_SUCCESS:
      return {
        ...state,
        loading: false,
        temp: action.payload,
      };
    case FACILITY_TEMP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
