import React from "react";
import noPermission from "../assets/images/undraw_Cancel_re_pkdm.png";

const NotAllowed = () => {
  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          {
            <div className="dash-stats-list" style={{ textAlign: "center" }}>
              <br />
              <br />
              <img
                alt=""
                src={noPermission}
                style={{ width: "145px", opacity: "0.5" }}
              />
              <br />
              <p style={{ opacity: "0.5" }}>No Permission</p>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default NotAllowed;
